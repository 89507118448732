<template>
    <div class="page" ref="chidren">

        <div class="homeMain">
            <img src="../img/Bannercha.png" alt="" class="bannerImg">
            <div class="homebannerBox">
                <div class="swiper mySwiper5">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in bannerDate" :key="index">

                            <img class="banner_img" :src="'https://public.rossoarts.com/static/uploads/' + item.pc_banner"
                                alt="" @click="bannerJop1(item.pc_link)">
                        </div>
                        <!-- <div class="swiper-slide newBannerSlide" style="position: relative;">
                            <div class="newBanner" @click="artNewJop(artsFirstId)">
                                <div class="newBanner_left">
                                    <div class="newBanner_top">
                                        <p>RoSSo</p>
                                        <div>
                                            <p>{{ artsFirst.create_time }}</p>
                                            <img src="../img/banNew/2.png" alt="">
                                        </div>
                                    </div>
                                    <div class="newBanner_title">
                                        <p>
                                            {{ artsFirst.title }}
                                        </p>
                                    </div>

                                    <img class="newBanner_bottom" src="../img/banNew/1.png" alt="">
                                </div>
                                <div class="newBanner_right">
                                    <img :src="'https://public.rossoarts.com/static/uploads/article/' + artsFirst.list_img"
                                        alt="">
                                </div>


                            </div>
                        </div> -->
                        <!-- <div class="swiper-slide">
              <img src="../img/10002.jpg" alt="" @click="bannerJop2()">
            </div>
            <div class="swiper-slide">
              <img src="../img/pc.jpg" alt="" @click="bannerJop3()">
            </div> -->

                    </div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-pagination"></div>

                </div>

            </div>

            <!-- <img alt="Vue logo" src="../img/Bannercha.png" class="testImg"> -->
        </div>


        <ul class="bannerAfter">
            <li>
                <h2 class="bannerAfterP1">22,000+</h2>
                <p class="bannerAfterP2">OFFER</p>
                <p class="bannerAfterP2">收割</p>
            </li>
            <li>
                <h2 class="bannerAfterP1">52.1%</h2>
                <p class="bannerAfterP2">奖学金</p>
                <p class="bannerAfterP2">比例助力</p>
            </li>

            <li>
                <h2 class="bannerAfterP1">10+1所</h2>
                <p class="bannerAfterP2">直营+海外校区 </p>
                <p class="bannerAfterP2">坐落核心地段</p>
            </li>
            <li>
                <h2 class="bannerAfterP1">1000+</h2>
                <p class="bannerAfterP2">海内外</p>
                <p class="bannerAfterP2">优质导师</p>
            </li>
            <li>
                <h2 class="bannerAfterP1">70+</h2>
                <p class="bannerAfterP2">背景提升项目</p>
                <p class="bannerAfterP2">提高核心竞争力</p>
            </li>
        </ul>


        <!-- 2023录取榜单 -->
        <div class="section1">

            <img src="../img/OFFERshou.png" alt="" class="s1_left_img" @click="schoolJop">


            <div class="s1_right">

                <div class="s1_right_title">
                    <div class="s1_right_starBox">
                        <img src="../img/Star 1.png" alt="" class="star">
                        <h1>RoSSo 录取榜单</h1>
                        <img src="../img/Star 1.png" alt="" class="star">
                    </div>
                    <p class="s1_title_p">RoSSo OFFERLIST</p>

                </div>

                <div class="s1_banner_box">

                    <div class="swiper mySwiper1">
                        <div class="swiper-wrapper">

                            <!-- 循环体 -->
                            <div class="swiper-slide" v-for="(item, index) in schoolData" :key="index">

                                <div @click="schoolDetail(item.x.id)">
                                    <!-- :src=item.school1.imgUrl -->
                                    <img :src="'https://public.rossoarts.com/static/uploads/' + item.x.logo" alt="">
                                    <p class="s1_cname">{{ item.x.cname }}</p>
                                    <p>录取<span>{{ item.x.admission_num }}</span>人</p>

                                </div>

                                <div @click="schoolDetail(item.y.id)">
                                    <img :src="'https://public.rossoarts.com/static/uploads/' + item.y.logo" alt="">
                                    <p class="s1_cname">{{ item.y.cname }}</p>
                                    <p>录取<span>{{ item.y.admission_num }}</span>人</p>
                                </div>

                            </div>


                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>



            </div>
        </div>

        <div class="section2">
            <div class="s2_cont">
                <div class="s2_item">
                    <img src="../img/Group 137.png" alt="">
                    <p class="s2_itemP1">总申请人数</p>
                    <p class="s2_itemP2">16000+人</p>
                </div>

                <div class="s2_item">
                    <img src="../img/Group 138.png" alt="">
                    <p class="s2_itemP1">梦校录取人数</p>
                    <!-- <p class="s2_itemP2">98.56%</p> -->
                    <p class="s2_itemP2">15700+人</p>
                </div>
                <div class="s2_item">
                    <img src="../img/Group 139.png" alt="">
                    <p class="s2_itemP1">本科OFFER</p>
                    <p class="s2_itemP2">10300+份</p>
                </div>
                <div class="s2_item">
                    <img src="../img/Group 140.png" alt="">
                    <p class="s2_itemP1">硕士OFFER</p>
                    <p class="s2_itemP2">11700+份</p>
                </div>

            </div>
        </div>

        <div class="section3">
            <div class="about">
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item name="1" sty>
                        <template slot="title">
                            <span class="xieP1">核心优势</span>
                            <i class="xieline"></i>
                            <span class="xieP2">RoSSo EXCLUSIVE</span>
                        </template>

                        <div class="s3_cont_box">
                            <div class="s3_cont1">
                                <div class="s31_item">
                                    <div class="cover_white">
                                        <div class="cover_cont1">
                                            <p class="cover_cont1_p1">教育体系全面升级</p>
                                            <p class="cover_cont1_p2">Upgrading Of All The Education System</p>
                                        </div>
                                        <div class="cover_cont2">
                                            <p class="cover_cont2_p1">六大科系全面升级</p>
                                            <p class="cover_cont2_p2">融入英美院校学习概念</p>
                                            <p class="cover_cont2_p3">培养跨学科综合实践能力</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="s31_item">
                                    <div class="cover_white">
                                        <div class="cover_cont1">
                                            <p class="cover_cont1_p1">多V1教育模式</p>
                                            <p class="cover_cont1_p2">Many-To-One Educational Mode</p>
                                        </div>
                                        <div class="cover_cont2">
                                            <p class="cover_cont2_p1">配置专属留学规划师</p>
                                            <p class="cover_cont2_p2">教学部安排多位导师授课</p>
                                            <p class="cover_cont2_p3">专属教务跟踪教学情况</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="s31_item">
                                    <div class="cover_white">
                                        <div class="cover_cont1">
                                            <p class="cover_cont1_p1">五维审核体系</p>
                                            <p class="cover_cont1_p2">Five-Dimensional Audit System</p>
                                        </div>
                                        <div class="cover_cont2">
                                            <p class="cover_cont2_p1">内部团队与外部团队协作</p>
                                            <p class="cover_cont2_p2">打造专业审核部门</p>
                                            <p class="cover_cont2_p3">严格把控教学质量</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="s31_item">
                                    <div class="cover_white">
                                        <div class="cover_cont1">
                                            <p class="cover_cont1_p1">海外院校导师团队</p>
                                            <p class="cover_cont1_p2">Tutor Team Of Overseas Institutions</p>
                                        </div>
                                        <div class="cover_cont2">
                                            <p class="cover_cont2_p1">只聘请海外院校研究生</p>
                                            <p class="cover_cont2_p2">解析院校申请痛点难点</p>
                                            <p class="cover_cont2_p3">10000+学生收获院校offer</p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })" class="s31_btn">即刻咨询</a>

                        </div>

                        <!-- <div class="card_bottom"></div> -->
                    </el-collapse-item>
                    <el-collapse-item name="2" >
                        <template slot="title">
                            <!-- <span class="xieP1">作品集课堂</span> -->
                            <span class="xieP1">艺术留学课堂</span>
                            <i class="xieline"></i>
                            <span class="xieP2">RoSSo ART COURSES</span>
                        </template>

                        <div class="s3_cont2">

                            <div class="swiper mySwiper4">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>1. VIP院校计划</p>
                                                <ul>
                                                    <li>无缝衔接院校课程体系</li>
                                                    <li>协议录取指定欧美院校</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/vip.html">
                                                    <div class="s3c2botright"></div>
                                                </a>

                                            </div>
                                        </div>
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>2. 专业作品集辅导</p>
                                                <ul>
                                                    <li>多位导师打造个性化作品集服务</li>
                                                    <li>在教学中培养学生自主能力</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/sample.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>3. 海外游学项目</p>
                                                <ul>
                                                    <li>提供国际名校寒暑假游学项目</li>
                                                    <li>抢先获得院校教授推荐信</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/promoting.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>4. BTEC艺术课程</p>
                                                <ul>
                                                    <li>培生爱德思官方授权</li>
                                                    <li>低成本高性价比申请通道</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/art_management.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>5. A-LEVEL课程</p>
                                                <ul>
                                                    <li>培生爱德思官方授权</li>
                                                    <li>实现学术与申请高效同步</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/grand_master.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>6. GA&GD创意课程</p>
                                                <ul>
                                                    <li>针对12-16 青少年系统地学习艺术与设计课程</li>
                                                    <li>1V1专属升学指导规划</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/gagd.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide">
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>7. AP课程</p>
                                                <ul>
                                                    <li>美本名校直录最佳助力</li>
                                                    <li>灵活授课，成绩可抵扣本科学分</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/aesthetics_promoting.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="s3_cont2_item">
                                            <div class="s3c2rig">
                                                <p>8. RoSSo College</p>
                                                <ul>
                                                    <li>与国际学校强强联手</li>
                                                    <li>全方面发展高素质人才</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/RossoCollege.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="s3_cont2_item" style="background: none;">
                                            <!-- <div class="s3c2rig">
                                                <p>6. GA&GD创意课程</p>
                                                <ul>
                                                    <li>针对12-16 青少年系统地学习艺术与设计课程</li>
                                                    <li>1V1专属升学指导规划</li>
                                                </ul>
                                            </div>
                                            <div class="s3c2bot">
                                                <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                                                    <div class="s3c2botleft"></div>
                                                </a>
                                                <a href="/PortfolioInfo/gagd.html">
                                                    <div class="s3c2botright"></div>
                                                </a>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- <div class="swiper-slide">
                    <div class="s3_cont2_item">
                      <div class="s3c2rig">
                        <p>7. 跨界艺术创意实践</p>
                        <ul>
                          <li>定期提供海外创意实践</li>
                          <li>充实学生的申请履历</li>
                        </ul>
                      </div>
                      <div class="s3c2bot">
                        <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                          <div class="s3c2botleft"></div>
                        </a>
                        <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                          <div class="s3c2botright"></div>
                        </a>
                      </div>
                    </div>
                    <div class="s3_cont2_item2"></div>
                    <div class="s3_cont2_item2"></div>

                  </div> -->

                                </div>
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-pagination"></div>

                            </div>





                        </div>

                        <!-- <div class="card_bottom"></div> -->
                    </el-collapse-item>
                    <el-collapse-item title="效率 Efficiency" name="3">
                        <template slot="title">
                            <!-- <span class="xieP1">作品集导师</span> -->
                            <span class="xieP1">艺术导师</span>
                            <i class="xieline"></i>
                            <span class="xieP2">ART TUTORS</span>
                        </template>

                        <div class="s33Box" id="s33Box">

                            <div class="s33clientBox">
                                <div class="s33banner" id="s33banner">
                                    <div class="s33Cont s33Cont1">

                                        <!-- R -->
                                        <div class="s33item" v-for="(item, index) in tutors1Data"
                                            @click="tutorsDetail(item.id)" :key="index">
                                            <div class="s33imgBox">
                                                <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                                    alt="">
                                                <img class="s33coverImg" v-if="index == 0 || index == 5"
                                                    src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                                <img class="s33coverImg"
                                                    v-if="index == 1 || index == 3 || index == 4 || index == 6"
                                                    src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                                <img class="s33coverImg" v-if="index == 2 || index == 7"
                                                    src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                            </div>
                                            <p class="s33p1">{{ item.name }}</p>
                                            <p class="s33p2">{{ item.major }}</p>
                                            <p class="s33p3">{{ item.school | schoolAll }}</p>
                                        </div>


                                    </div>

                                    <div class="s33Cont s33Cont2">
                                        <div class="s33item" v-for="(item, index) in tutors2Data"
                                            @click="tutorsDetail(item.id)" :key="index">
                                            <div class="s33imgBox">
                                                <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                                    alt="">
                                                <img class="s33coverImg" v-if="index == 0 || index == 5"
                                                    src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                                <img class="s33coverImg"
                                                    v-if="index == 1 || index == 3 || index == 4 || index == 6"
                                                    src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                                <img class="s33coverImg" v-if="index == 2 || index == 7"
                                                    src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                            </div>
                                            <p class="s33p1">Prof.{{ item.name }}</p>
                                            <p class="s33p2">{{ item.major }}</p>
                                            <p class="s33p3">{{ item.school | schoolAll }}</p>
                                        </div>

                                    </div>



                                </div>
                            </div>


                            <div class="s33btnBox">
                                <div class="s33pre"></div>
                                <div class="s33nex"></div>
                                <!-- <img src="../img/homepage-折叠部分切图/youbegin.png" alt="" class="s33nex" @click="s33next()"> -->
                                <img src="../img/homepage-折叠部分切图/Group 196.png" alt="">
                            </div>
                            <div class="s33titleTop">
                                <div class="s33titleBox">
                                    <p class="s33blue" id="s33blue"></p>
                                    <p class="s33togItem" @click="s33pre()">学科带头人</p>
                                    <p class="s33togItem" @click="s33next()">海外大师</p>
                                </div>
                            </div>
                            <div class="s33bottom" @click="tutorsJop">

                            </div>
                        </div>

                        <!-- <div class="card_bottom"></div> -->
                    </el-collapse-item>
                    <el-collapse-item title="可控 Controllability" name="4">
                        <template slot="title">
                            <!-- <span class="xieP1">优秀作品集案例</span> -->
                            <span class="xieP1">优秀案例精选</span>
                            <i class="xieline"></i>
                            <span class="xieP2">SELECTED CASES</span>
                        </template>

                        <div class="s34_cont">
                            <div class="swiper mySwiper2">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="(item, index) in selectedData" :key="index">
                                        <div class="s34item" v-for="(item2, index2) in item" @click="caseDetail(item2.id)" :key="index2">
                                            <p class="s34_p1">RoSSo</p>
                                            <img :src="'https://public.rossoarts.com/static/uploads/case/' + item2.portfolioShow"
                                                alt="">
                                            <p class="s34_p2">{{ item2.name }}</p>
                                            <p class="s34_p3">{{ item2.cname }}</p>
                                            <p class="s34_p3">{{ item2.school | schoolSplit }}</p>
                                        </div>

                                    </div>




                                </div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>

                        <!-- <div class="card_bottom"></div> -->
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="shilineBox" style="height: 140px;">
            <div class="rLine"></div>
            <div class="cLine"></div>
        </div>

        <!-- rosso无序艺术 -->
        <div class="section8">
            <div class="section_title">
                <p class="section_cn">洛素制造</p>
                <p class="section_en">BY RoSSo</p>
            </div>
            <div class="s8_cont">
                <div class="s8_left">
                    <div class="s8l_top">By RoSSo</div>
                    <!-- <i class="s81_line"></i> -->
                    <div class="s8_p1">
                        是由RoSSo国际艺术留学于2020年创立的独立艺术聚合空间，集结优秀青年艺术家、独立艺术品牌并为其提供商业化桥梁，为大众呈现冰山之下的当代语境美学以及非常规艺术设计作品。
                    </div>
                    <div class="s8_p2">
                        By RoSSo 的品牌愿景不止于打造青年艺术家/品牌的交流及孵化平台，也希望为每一份热爱提供恒久燃烧的火把。
                    </div>
                    <img src="../img/Group 237.png" alt="" @click="collectionJop"
                        style="width: 214px;margin-top: 20px;cursor: pointer;">
                </div>
                <div class="s8_right">
                    <div class="s8clientBox">

                        <div class="s8banner" id="s8banner">
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>
                                <div class="s8img3"></div>
                            </div>
                        </div>

                    </div>

                    <div class="s8_bot">
                        <div class="s8pre" @click="s8pre()"></div>
                        <div class="s8name">
                            <p id="collectionName">Caoyang</p>
                            <p id="artsName">「 Plastic love 」</p>
                        </div>
                        <div class="s8nex" @click="s8next()"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section4">
            <div class="section_title">
                <p class="section_cn">最新资讯&活动</p>
                <p class="section_en">NEWS & EVENTS</p>
            </div>
            <div class="s4_cont">
                <div class="s4_left" @click="artNewJop(artsFirstId)">
                    <div class="dbimgboxFirst">
                        <img :src="'https://public.rossoarts.com/static/uploads/article/' + artsFirst.list_img" alt="">
                        <img src="../img/zixun11.png" alt="">
                    </div>
                    <p>{{ artsFirst.create_time }}</p>
                    <h2>最新资讯</h2>
                    <div class="s4_left_p2">{{ artsFirst.title }}</div>
                </div>
                <div class="s4_right">
                    <div class="aboutS4">
                        <el-carousel class="slideLeftRight" indicator-position="outside" arrow="never" :autoplay="true"
                            height="450px" ref="slideCarousel" @change="toggleCard">
                            <el-carousel-item v-for="(item, index) in artsNewdata" :key="index">

                                <div class="s4_right_item" @click="artNewJop(item.x.id)">

                                    <div class="dbimgbox">
                                        <img class="zixun1"
                                            :src="'https://public.rossoarts.com/static/uploads/article/' + item.x.list_img"
                                            alt="">
                                        <img class="coverZixun1" src="../img/zixun22.png" alt="">
                                    </div>
                                    <p>{{ item.x.create_time }}</p>
                                    <div class="h3">{{ item.x.title }}</div>
                                </div>
                                <div class="s4_right_item" @click="artNewJop(item.y.id)">
                                    <div class="dbimgbox">
                                        <img class="zixun1"
                                            :src="'https://public.rossoarts.com/static/uploads/article/' + item.y.list_img"
                                            alt="">
                                        <img class="coverZixun1" src="../img/zixun1.png" alt="">
                                    </div>
                                    <p>{{ item.y.create_time }}</p>
                                    <div class="h3">{{ item.y.title }}</div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>

                        <div class="pos_num">
                            <div class="posnum" style="color: #FB6333;" @mouseenter="toggleCardBang(0)">01</div>
                            <div class="posnum" @mouseenter="toggleCardBang(1)">02</div>
                            <div class="posnum" @mouseenter="toggleCardBang(2)">03</div>
                            <div class="posnum" @mouseenter="toggleCardBang(3)">04</div>
                            <div class="posnum" @mouseenter="toggleCardBang(4)">05</div>
                            <div class="posnum" @mouseenter="toggleCardBang(5)">06</div>
                        </div>
                    </div>




                </div>
            </div>
        </div>

        <!-- <div class="section45">
            <div class="s45_cont">
                <div class="s45_left"></div>
                <div class="s45_right">
                    <el-form :inline="true" :label-position="labelPosition" label-width="" :model="formLabelAlign">
                        <el-form-item label="姓名：">
                            <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="电话：">
                            <el-input v-model="formLabelAlign.tel" placeholder="请输入电话"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱：">
                            <el-input v-model="formLabelAlign.mail" placeholder="请输入邮箱"></el-input>
                        </el-form-item>
                        <div class="s45_btn" @click="onSubmit()">
                            <div class="s45_btn_cont">
                                <p>点我提交领取</p>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div> -->

        <div class="section5">
            <div class="section_title">
                <p class="section_cn">全国教学环境</p>
                <p class="section_en">TEACHING ENVIRONMENTS</p>
            </div>

            <div class="s5_cont">
                <div class="s5_area">
                    <div class="envItem" @mouseenter="envChange(0)">上海 (总部)</div>
                    <div class="envItem" @mouseenter="envChange(1)">北京</div>
                    <div class="envItem" @mouseenter="envChange(2)">南京</div>
                    <div class="envItem" @mouseenter="envChange(3)">成都</div>
                    <div class="envItem" @mouseenter="envChange(4)">杭州</div>
                    <div class="envItem" @mouseenter="envChange(5)">广州</div>
                    <div class="envItem" @mouseenter="envChange(6)">深圳</div>
                    <div class="envItem" @mouseenter="envChange(7)">武汉</div>
                    <div class="envItem" @mouseenter="envChange(8)">西安</div>
                </div>

                <div class="s5_env">
                    <div class="s5_left">
                        <div class="s5_zuoshang">
                            <p class="s5_p1" id="s5p1">上海总部</p>
                            <p class="s5_p2" id="s5p2">SHANGHAI</p>
                            <p class="s5_p3" id="s5p3">上海徐汇区文定路208号A座301-302室301-302室</p>
                            <div class="s5_line"></div>
                        </div>
                        <div class="s5_img1" :style="{ backgroundImage: 'url(' + envMainImg1 + ')' }"></div>
                        <div class="s5_img2" :style="{ backgroundImage: 'url(' + envMainImg2 + ')' }"></div>
                        <div class="s5_img3" :style="{ backgroundImage: 'url(' + envMainImg3 + ')' }"></div>


                    </div>
                    <div class="s5_right" :style="{ backgroundImage: 'url(' + envMainImg4 + ')' }">

                    </div>

                </div>

            </div>

        </div>

        <SectionInp></SectionInp>



        <div class="section6">
            <div class="section_title">
                <p class="section_cn">RoSSo荣誉</p>
                <p class="section_en">RoSSo HONORS</p>
            </div>
            <div class="s6_cont">

                <div class="s6_left">
                    <div class="swiper mySwiper3">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors5.jpg" alt="">
                                <p>早稻田EDU官方授权合作中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors6.jpg" alt="">
                                <p>日本文化外国语学院指定招生中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors7.jpg" alt="">
                                <p>朗思Language Cert2022年度最佳合作伙伴</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors3.jpg" alt="">
                                <p>2022年获得培生爱德思考试局 “摘星揽月”奖 年度优秀教学中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors1.jpg" alt="">
                                <p>2020回响中国腾讯教育年度盛典影响力国际教育品牌</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors4.jpg" alt="">
                                <p>2020年度百度营销最具品牌影响力</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors8.jpg" alt="">
                                <p>2020年度最具品牌影响力</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors9.jpg" alt="">
                                <p>2020年度影响力国际教育品牌</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors2.jpg" alt="">
                                <p>2019年度新浪教育盛典家长信赖教育培训品牌</p>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-pagination"></div>
                    </div>

                </div>

                <div class="s6_right">
                    <ul id="honorsUl">
                        <li>英国培生爱德思学术及职业考试机构认证中心</li>
                        <li>英国国家高中课程A Level考试局官方办学中心</li>
                        <li>英国爱德思考试局官方授权GCSE考点</li>
                        <li>英国BTEC预科课程官方办学中心</li>
                        <li>英国国家职业学历与学术考试官方认证中心</li>
                        <li>英国伦敦圣三一考试院官方考点</li>
                        <li>EPQ研究型学习认证项目</li>
                        <li>英国爱德思考试局官方授权考点</li>
                        <li>英国大学招生服务中心UCAS认证考评中心</li>
                        <li>英国资格认证和考试管理办公室朗思测评合作中心</li>
                        <li>腾讯留学缴费官方指定缴费基地</li>
                        <li>伦敦艺术大学UAL特殊预审中心</li>
                        <li>日本文化集团大陆事务所指定合作单位</li>
                        <li>日本文化外国语学院指定招生中心</li>
                        <li>伦敦艺术大学UAL授权国际预科课程IFD合作中心</li>
                        <li>伯恩茅斯艺术大学官方合作单位</li>
                    </ul>
                    <img src="../img/Group 235.png" alt="" id="moreHonors" @click="moreHonors()">
                </div>
            </div>
        </div>
        <div class="shilineBox" style="height: 140px;">
            <div class="rLine"></div>
            <div class="cLine"></div>
        </div>

        <div class="section7">
            <div class="section_title">
                <p class="section_cn">合作伙伴</p>
                <p class="section_en">COOPERATION ORGANIZATIONS</p>
            </div>
            <div class="s7_cont">
                <div class="s7_banner">

                    <div class="s7_line s7_banner3">
                        <img src="../img/合作伙伴最新版/院校/jq.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jzys.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/dmms.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lfb.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/tsd.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/glsg.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ytdx.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/meb.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nyu.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nydy.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/MI.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/FIDM.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/saic.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lwp.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsd.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/mllk.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/jq.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jzys.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/dmms.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lfb.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/tsd.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/glsg.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ytdx.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/meb.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nyu.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nydy.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/MI.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/FIDM.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/saic.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lwp.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsd.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/mllk.jpg" alt="">
                    </div>


                    <div class="s7_line s7_banner2" @mouseenter="pausebanner" id="pauseTest">


                        <img src="../img/合作伙伴最新版/院校/Parsons.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ACCD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/Partt.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/RCA.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/RISD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/SCAD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/SVA.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/UCLA.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsms.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ual.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/bkl.png" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1977.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1978.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1979.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/wecom-temp-58464-c753312f1019c336039cf760187a5180.png" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RoSS0 X apple.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/Parsons.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ACCD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/Partt.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/RCA.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/RISD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/SCAD.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/SVA.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/UCLA.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsms.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ual.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/bkl.png" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1977.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1978.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1979.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/wecom-temp-58464-c753312f1019c336039cf760187a5180.png" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RoSS0 X apple.jpg" alt="">


                    </div>



                    <div class="s7_line">
                        <img src="../img/合作伙伴最新版/品牌/2021 OpenFile Generic Proposal_CHN-compressed.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RBDA_Logo_White.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/TXLOGO.jpg" alt=""> <!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1961.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1962.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1963.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1964.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1965.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1967.jpeg" alt=""><!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1970.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1971.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1972.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1973.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1974.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1975.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1976.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/2021 OpenFile Generic Proposal_CHN-compressed.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RBDA_Logo_White.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/TXLOGO.jpg" alt=""> <!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1961.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1962.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1963.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1964.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1965.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1967.jpeg" alt=""><!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1970.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1971.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1972.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1973.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1974.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1975.jpeg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1976.jpeg" alt="">

                    </div>
                </div>
            </div>


        </div>
        <!-- page div -->
    </div>
</template>

<script>

import Swiper from 'swiper/bundle'
import 'swiper/css';
import "swiper/css/effect-flip";
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import axios from "axios";
import SectionInp from '@/components/SectionInp.vue'



export default {
    name: 'HomeView',
    components: {
        SectionInp
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            labelPosition: 'right',
            formLabelAlign: {
                name: '',
                tel: '',
                region: '',
                region2: '',
                major: '',
            },
            activeNames: ['1'],
            schoolData: [],

            s5I: 0,
            s33I: 0,
            s8I: 0,
            collectionName: ["Caoyang", "Jiayu", "Matti", "Qingyang", "Ruiling", "Yaosichen"],
            artsName: ["「 Plastic love 」", "「 Bang Gril 」", "「 Empathy 」", "「 Hearing final」", "「 Face Blind 」", "「 Sub 」"],
            envCont: [
                {
                    name: "上海（总部）",
                    ename: "SHANGHAI",
                    address: "上海徐汇区文定路208号A座301-302室"
                },
                {
                    name: "北京",
                    ename: "BEIJING",
                    address: "一校：北京市朝阳区百子湾路苹果社区北区32号院6号楼88号 B座｜二校：海淀北二街8号中关村SOHO"
                },
                {
                    name: "南京",
                    ename: "NANJING",
                    address: "南京市秦淮区中山南路1号南京中心20楼"
                },
                {
                    name: "成都",
                    ename: "CHENGDU",
                    address: "成都市锦江区成都国际金融中心IFS一号楼3203-3206室"
                },
                {
                    name: "杭州",
                    ename: "HANGZHOU",
                    address: "杭州市西湖区学院路77号黄龙万科中心H座7层"
                },
                {
                    name: "广州",
                    ename: "GUANGZHOU",
                    address: "广州市天河区天河北路233号中信广场7308室"
                },
                {
                    name: "深圳",
                    ename: "SHENZHEN",
                    address: "深圳市福田区金田路东福华路北岗厦皇庭中心37楼"
                },
                {
                    name: "武汉",
                    ename: "WUHAN",
                    address: "武汉市武昌区中北路9号长城汇T3-2F"
                },
                {
                    name: "西安",
                    ename: "XI’AN",
                    address: "西安市碑林区长安国际中心C座"
                },
            ],
            envImg: [
                [
                    require("../img/各校区门头图/上海/官网/1.jpg"),
                    require("../img/各校区门头图/上海/官网/2.jpg"),
                    require("../img/各校区门头图/上海/官网/3.jpg"),
                    require("../img/各校区门头图/上海/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/北京/官网/1.jpg"),
                    require("../img/各校区门头图/北京/官网/2.jpg"),
                    require("../img/各校区门头图/北京/官网/3.jpg"),
                    require("../img/各校区门头图/北京/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/南京/官网/1.jpg"),
                    require("../img/各校区门头图/南京/官网/2.jpg"),
                    require("../img/各校区门头图/南京/官网/3.jpg"),
                    require("../img/各校区门头图/南京/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/成都/官网/1.jpg"),
                    require("../img/各校区门头图/成都/官网/2.jpg"),
                    require("../img/各校区门头图/成都/官网/3.jpg"),
                    require("../img/各校区门头图/成都/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/杭州/官网/1.jpg"),
                    require("../img/各校区门头图/杭州/官网/2.jpg"),
                    require("../img/各校区门头图/杭州/官网/3.jpg"),
                    require("../img/各校区门头图/杭州/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/广州/官网/1.jpg"),
                    require("../img/各校区门头图/广州/官网/2.jpg"),
                    require("../img/各校区门头图/广州/官网/3.jpg"),
                    require("../img/各校区门头图/广州/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/深圳/官网/1.jpg"),
                    require("../img/各校区门头图/深圳/官网/2.jpg"),
                    require("../img/各校区门头图/深圳/官网/3.jpg"),
                    require("../img/各校区门头图/深圳/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/武汉/官网/1.jpg"),
                    require("../img/各校区门头图/武汉/官网/2.jpg"),
                    require("../img/各校区门头图/武汉/官网/3.jpg"),
                    require("../img/各校区门头图/武汉/官网/4.jpg"),
                ],
                [
                    require("../img/各校区门头图/西安/官网/1.jpg"),
                    require("../img/各校区门头图/西安/官网/2.jpg"),
                    require("../img/各校区门头图/西安/官网/3.jpg"),
                    require("../img/各校区门头图/西安/官网/4.jpg"),
                ],
            ],
            // envMainImg:"",
            envMainImg1: require("../img/各校区门头图/上海/官网/1.jpg"),
            envMainImg2: require("../img/各校区门头图/上海/官网/2.jpg"),
            envMainImg3: require("../img/各校区门头图/上海/官网/3.jpg"),
            envMainImg4: require("../img/各校区门头图/上海/官网/4.jpg"),

            s33BgcImg: require("../img/homepage-折叠部分切图/Group\ 2902.png"),
            artsNewdata: [],
            artsFirst: [],
            artsFirstId: 906,
            tutors1Data: [],
            tutors2Data: [],
            selectedData: [],
            bannerDate: [],
            offerSrc: "",
        }
    },
    created() {


        this.getArtsNew();

        this.getTutors1()
        this.getTutors2()
        this.getSelected()
        this.getPCbanner()
        this.getNewsId()
        // this.$refs[child].$el.innerHTML
        // 或者


    },
    filters: {
        schoolSplit(val) {
            // const valLength = val.split("/").length
            // return valLength<2?val.split("/")[0]:val.split("/")[0] +"/" + val.split("/")[1]
            return val.split("/")[0]
        },
        schoolAll(val) {
            return val.split(",")[0]
        },
    },
    methods: {
        caseDetail(val) {
            this.$router.push(`/case/${val}.html`)
        },
        arrGroup(array, sublength) {
            let index = 0;
            let newArray = []
            while (index < array.length) {
                newArray.push(array.slice(index, index += sublength))
            }
            return newArray
        },
        getSelected() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstCase?limit=24&page=1").then((data) => {
                // console.log(data.data);
                // console.log(this.arrGroup(data.data, 6));
                this.selectedData = this.arrGroup(data.data, 6)
                this.$nextTick(() => {
                    this.mySwiper2Init()
                })
            })
        },
        getPCbanner() {
            axios.get("https://public.rossoarts.com/api/v1/getPcBanner").then((data) => {
                // console.log(data.data.data);
                this.bannerDate = data.data.data
                data.data.data.forEach((item, index) => {
                    if (item.id == 30) {
                        this.offerSrc = item.pc_link
                        // console.log(this.offerSrc);
                    }
                });

                // this.$nextTick(() => {
                //     this.mySwiper5Init()
                // })
            })
        },
        getTutors1() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstTeacher?category=1&limit=8").then((data) => {
                // console.log(data.data);
                this.tutors1Data = data.data
            })
        },
        getTutors2() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstTeacher?category=2&limit=8").then((data) => {
                // console.log(data.data);
                this.tutors2Data = data.data
            })
        },
        getSchool() {
            console.log('res')
            axios.get("https://public.rossoarts.com/api/v1/getFirstOffer?limit=36&page=1").then((data) => {
                // console.log(data.data.data);
                const arr = data.data.data
                const res = [];
                for (let i = 0; i < arr.length - 1; i += 2) {
                    let index = Math.floor(i / 2);
                    const obj = {
                        x: arr[i],
                        y: arr[i + 1],
                        z: i
                    };
                    res[index] = obj;
                }
                console.log("res school", res);
                this.schoolData = res
                this.$nextTick(() => {
                    this.mySwiper1Init()
                })
            })
        },
        getArtsNew() {
            axios.get("https://public.rossoarts.com/api/v1/getArtsNews?category=0&limit=13&page=1").then((data) => {
                // console.log(data, "资讯");
                // this.artsFirst = data.data.data.data[0]
                // this.artsFirstId = data.data.data.data[0].id

                // console.log(this.artsFirstId, "artsFirstId");
                // var arr = data.data.data.data.splice(1, data.data.data.data.length);
                var arr = data.data.data.data;
                // console.log(data.data.data.data.splice(1, data.data.data.data.length));
                const res = [];
                for (let i = 0; i < arr.length - 1; i += 2) {
                    let index = Math.floor(i / 2);
                    const obj = {
                        x: arr[i],
                        y: arr[i + 1],
                    };
                    res[index] = obj;
                }
                // console.log("res", res);

                this.artsNewdata = res
                this.$nextTick(() => {
                    this.mySwiper5Init()
                })
            })
        },
        getNewsId(val) {
            axios.get(`https://public.rossoarts.com/api/v1/getArtsNew?id=906`).then((data) => {
                // console.log(data.data.data);
                this.artsFirst = data.data.data
            })
        },
        artNewJop(val) {
            // window.open(`https://public.rossoarts.com/news/${val}.html`, '_blank')
            this.$router.push(`/news/${val}.html`)
        },
        pausebanner() {
            // pauseTest.style.animation = "pause"
        },
        // jopwlh
        tutorsJop() {
            this.$router.push("/teacher.html")
        },
        tutorsDetail(val) {
            console.log(val);
            this.$router.push(`/teachers/${val}.html`)
        },
        schoolDetail(val) {
            console.log(val);
            this.$router.push(`/schools/${val}.html`)
        },
        schoolJop() {
            // window.open('https://public.rossoarts.com/case-0-1-0.html', '_blank')
            this.$router.push(`${this.offerSrc}`)
        },
        collectionJop() {
            this.$router.push(`/news/866.html`)
        },
        jopkefu() {
            // kefu._MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })
            // window.open("https://www11c1.53kf.com/webCompany.php?kf_sign=DI0MjMTY4MkzODE1NTc0NTYwMDMzMDAzNzIxNjgzNTM=&arg=10168353&style=1&guest_id=12950638033002&language=zh-cn&charset=gbk&referer=http%3A%2F%2Fwww.rossoarts.com%2F&keyword=http%3A%2F%2Flocalhost%3A8081%2F&tpl=crystal_blue&uid=392070b4b477c77869f6a6a47a1dfee8&is_group=&timeStamp=1680242953439&ucust_id=", '_blank')
        },
        bannerJop1(val) {
            // console.log(val);
            if (val == "https://tb.53kf.com/code/client/10168353/1") {
                window.open(val, '_blank')
            } else if (val == "http://music.rossoarts.com/") {
                window.open(val, '_blank')
            } else {
                window.open(`https://www.rossoarts.com${val}`, '_blank')
                // this.$router.push(`${val}`)
            }

        },
        bannerJop2() {
            window.open("https://mp.weixin.qq.com/s/VdWVX96GFAArJM0t6U7yCg?vid=1688856216435544&deviceid=36ed1ffe-feec-4be1-af4f-ecc8f3e09bc3&version=4.1.3.6008&platform=win", '_blank')
        },
        bannerJop3() {
            // window.open("http://www.rossoarts.com/news/580.html", '_blank')
            this.$router.push("/courses")
        },
        // https://public.rossoarts.com/index/common/getform
        // https://public.rossoarts.com/index/common/getform
        onSubmit() {
            // console.log('submit!',this.formInline);
            var currentURL = window.location.href;
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }

            var patt = /^1[3-9][0-9]{9}$/
            if (this.formLabelAlign.name == "" || this.formLabelAlign.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');
            } else {
                // console.log("不是空");
                if (patt.test(this.formLabelAlign.tel)) {
                    console.log("可以发请求", status);

                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/index/common/getform", {
                        "name": this.formLabelAlign.name,
                        "tel": this.formLabelAlign.tel,
                        "major": this.formLabelAlign.major,
                        "url": window.location.href,
                        "status": status,
                    }).then((data) => {
                        console.log(data, "response");
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }).catch(function (error) {
                        console.log(error, "error");
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }


        },
        moreHonors() {
            honorsUl.style.height = "640px"
            moreHonors.style.display = "none"
        },
        envChange(val) {
            var envItems = document.getElementsByClassName("envItem")

            for (var i = 0; i < envItems.length; i++) {
                envItems[i].style.backgroundColor = "#fff"
                envItems[i].style.color = "#000"
            }
            envItems[val].style.backgroundColor = "rgba(95, 157, 247, 1)"
            envItems[val].style.color = "#fff"
            // console.log(val, this.envImg[val],s5Img1[0].style);
            s5p1.innerHTML = this.envCont[val].name
            s5p2.innerHTML = this.envCont[val].ename
            s5p3.innerHTML = this.envCont[val].address

            this.envMainImg1 = this.envImg[val][0]
            this.envMainImg2 = this.envImg[val][1]
            this.envMainImg3 = this.envImg[val][2]
            this.envMainImg4 = this.envImg[val][3]

        },
        oneEnv() {
            var envItems = document.getElementsByClassName("envItem")
            envItems[0].style.backgroundColor = "rgba(95, 157, 247, 1)"
            envItems[0].style.color = "#fff"
        },
        s8next() {
            this.s8I++;
            var s8rTop = document.getElementsByClassName("s8r_top")
            s8banner.style.transform = `translateX(${-620 * this.s8I}px)`
            s8banner.style.transition = 'all .36s'
            if (this.s8I == 6) {
                this.s8I = 0;
                setTimeout(() => {
                    s8banner.style.transform = 'translate(0,0)';
                    s8banner.style.transition = 'none'
                }, 360);

            }



            // console.log("s8nex", this.s8I);
            collectionName.innerHTML = this.collectionName[this.s8I]
            artsName.innerHTML = this.artsName[this.s8I]
        },
        s8pre() {
            this.s8I--;
            if (this.s8I == -1) {
                this.s8I = 5;

                s8banner.style.transition = 'none'

                s8banner.style.transform = `translateX(${-620 * (this.s8I + 1)}px)`
                setTimeout(() => {


                    s8banner.style.transform = 'translateX(' + (-this.s8I * 620) + 'px)';
                    s8banner.style.transition = 'all .36s'

                }, 1);


            } else {
                s8banner.style.transition = 'all 0.36s'
                s8banner.style.transform = `translateX(${-620 * this.s8I}px)`
            }


            collectionName.innerHTML = this.collectionName[this.s8I]
            artsName.innerHTML = this.artsName[this.s8I]
        },
     


        s33next() {
            this.s33I++;


            // console.log("s33nex", this.s33I);
            if (this.s33I < 2) {
                s33banner.style.transform = `translateX(${-1076 * this.s33I}px)`;



            } else {
                this.s33I = 1
            }
            s33blue.style.transform = `translateX(${240 * this.s33I}px)`;
            this.s33togTitle()
        },
        s33pre() {
            this.s33I--;

            // console.log("s33pre", this.s33I);
            if (this.s33I > -1) {
                s33banner.style.transform = `translateX(${-1076 * this.s33I}px)`
            } else {
                this.s33I = 0
            }
            s33blue.style.transform = `translateX(${-240 * this.s33I}px)`;
            this.s33togTitle()
        },
        oneBgcImage() {
            var els = document.getElementsByClassName("el-collapse-item__header")
            var xieline = document.getElementsByClassName("xieline")
            var elicon = document.getElementsByClassName("el-collapse-item__arrow")
            els[0].style.backgroundImage = "linear-gradient(to bottom,rgb(119,162,245), rgba(255,247,233))"
            els[0].style.fontWeight = "bold";
            elicon[0].style.fontWeight = "bold";
            els[0].style.borderWidth = "4px";
            xieline[0].style.height = "4px";
        },

        handleChange(val) {
            // console.log(val);
            var els = document.getElementsByClassName("el-collapse-item__header")
            var elicon = document.getElementsByClassName("el-collapse-item__arrow")
            var xieline = document.getElementsByClassName("xieline")

            for (var i = 0; i < els.length; i++) {
                els[i].style.backgroundImage = "linear-gradient(to bottom,rgba(207,225,253), rgba(255,247,233))"
                els[i].style.fontWeight = "400";
                elicon[i].style.fontWeight = "300";
                els[i].style.borderWidth = "2px";
                xieline[i].style.height = "2px";
            }
            for (var j = 0; j < val.length; j++) {

                els[val[j] - 1].style.backgroundImage = "linear-gradient(to bottom,rgb(119,162,245), rgba(255,247,233))"
                els[val[j] - 1].style.fontWeight = "bold";
                elicon[val[j] - 1].style.fontWeight = "bold";
                els[val[j] - 1].style.borderWidth = "4px";
                xieline[val[j] - 1].style.height = "4px";

            }


        },
        next() {
            // this.s5I ++;
            var s5Items = document.getElementsByClassName("s5_item")
            this.s5I > s5Items.length - 3 ? s5Items.length - 2 : this.s5I++;
            // console.log(this.s5I,s5Items.length);
            s5cont.style.transform = `translateX(${-722 * this.s5I}px)`;
        },
        prev() {
            this.s5I < 1 ? 0 : this.s5I--;
            s5cont.style.transform = `translateX(${-722 * this.s5I}px)`;
        },
        toggleCardBang(val) {
            var os = document.getElementsByClassName("el-carousel__indicator")
            // console.log(val,"toggleCardBang",os.length);
            os[val].click()
        },
        toggleCard(val) {
            // console.log(val);
            var posnums = document.getElementsByClassName("posnum")
            for (var i = 0; i < posnums.length; i++) {
                posnums[i].style.color = "#000"
            }
            posnums[val].style.color = "#FB6333"
        },
        // 滑动切换
        slideBanner() {
            //选中的轮播图
            var box = document.querySelector('.slideLeftRight');
            var startPoint = 0;
            var stopPoint = 0;
            //重置坐标
            var resetPoint = function () {
                startPoint = 0;
                stopPoint = 0;
            }
            //手指按下
            box.addEventListener("mousedown", function (e) {
                //手指点击位置的X坐标
                startPoint = e.changedTouches[0].pageX;
                console.log(startPoint);
                //手指滑动
                box.addEventListener("mousemove", function (e) {
                    //手指滑动后终点位置X的坐标
                    stopPoint = e.changedTouches[0].pageX;
                });
            });

            //当手指抬起的时候，判断图片滚动离左右的距离
            let that = this
            box.addEventListener("mouseup", function (e) {
                console.log("1：" + startPoint);
                console.log("2：" + stopPoint);
                if (stopPoint == 0 || startPoint - stopPoint == 0) {
                    resetPoint();
                    return;
                }
                if (startPoint - stopPoint > 0) {
                    resetPoint();
                    that.$refs.slideCarousel.next();
                    return;
                }
                if (startPoint - stopPoint < 0) {
                    resetPoint();
                    that.$refs.slideCarousel.prev();
                    return;
                }
            });
        },


        scrollToTop() {
            let imgShou = document.querySelector('#imgShou')
            let demoHeight = document.querySelector('#imgShou').offsetTop
            // window.addEventListener('scroll', function () {
            var scrollTop = document.documentElement.scrollTop
            // console.log(document.documentElement.scrollTop, '==', demoHeight)
            if (scrollTop >= (demoHeight - 200)) {

                imgShou.classList.add("animated")
                imgText.classList.add("animated")
                imgShou.classList.add("rotateInDownLeft")
                imgText.classList.add("rollIn")
                // console.log("够了");
            } else {

            }
            // })
        },
        s33togTitle() {
            var s33togItem = document.getElementsByClassName("s33togItem")
            for (var i = 0; i < s33togItem.length; i++) {

                s33togItem[i].style.color = "#000"
            }

            s33togItem[this.s33I].style.color = "#fff"
            // console.log(s33togItem, this.s33I);

        },
        mySwiper1Init() {
            new Swiper('.mySwiper1', {
                clickable: true,
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3,
                observer: true,
                loop: true,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false,
                },
                loopFillGroupWithBlank: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },

            })
        },
        mySwiper2Init() {
            new Swiper(".mySwiper2", {
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            })
        },
        mySwiper5Init() {
            var swiper = new Swiper(".mySwiper5", {
                observer: true,
                clickable: true,
                loop: true,
                // autoplay: {
                //     delay: 10000,
                //     disableOnInteraction: false,
                // },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },

            });
        },

    },
    updated() {

    },
    mounted() {
        // console.log("updated（", window.innerWidth);
        // var newBanner = document.getElementsByClassName("newBanner")
        var homebannerBox = document.getElementsByClassName("homebannerBox")
        // newBanner[0].style.height = window.innerWidth * 0.85 * 470 / 1920 + "px"
        homebannerBox[0].style.height = window.innerWidth * 0.85 * 470 / 1920 - 5 + "px"


        var currentURL = window.location.href;
        var status = ''
        // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
        if (currentURL.includes("google")) {
            status = "用户正在使用 Google 搜索引擎"
        } else if (currentURL.includes("bing")) {
            status = "用户正在使用 Bing 搜索引擎"
        } else if (currentURL.includes("yahoo")) {
            status = "用户正在使用 Yahoo 搜索引擎"
        } else if (currentURL.includes("baidu")) {
            status = "用户正在使用 百度 搜索引擎"
        } else {
            status = "无法确定用户使用的搜索引擎"
        }


       



        // console.log(this.$refs.chidren.innerHTML);
        var referrer = document.referrer;
        // console.log(referrer, "0830", window.location.href);


        window.addEventListener('scroll', this.scrollToTop, true)
        // this.slideBanner()
        this.getSchool();
        // console.log("myswiper");
        this.s33togTitle()
        this.scrollToTop()

        this.oneBgcImage();
        this.oneEnv();
        var swiper3 = new Swiper(".mySwiper3", {
            effect: "flip",
            loop: true,
            grabCursor: true,

            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        var swiper = new Swiper(".mySwiper4", {

            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },


        });



    },
    destroyed() {

        window.removeEventListener("scroll", this.scrollToTop, true);
    },
}

</script>
<!-- wlh -->
<style lang="less" >
.section45 {
    width: 100%;

    overflow: hidden;
    height: 280px;
    // border: 1px solid red;
    margin: 140px 0;
    // background-color: #5F9DF7;

    .s45_cont {
        // width: 1280px;
        width: 100%;
        // border: 1px solid #000;
        // min-width: 1280px;
        overflow: hidden;
        height: 280px;
        margin: 0 auto;
        display: flex;
        background: url(../img/s45bgc.jpg) 50% no-repeat;
        background-size: 100% 100%;
        // background-position:100px 50%;
        justify-content: center;
        align-items: center;

        .s45_left {
            width: 530px;
            height: 245px;
            // border: 1px solid #000;
            background: url(../img/s45cont.png) 50% no-repeat;
            background-size: 100%;
        }

        .s45_right {
            // width: 340px;
            height: 280px;
            // border: 1px solid #000;
            margin-left: 40px;

            .el-form {
                height: 280px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .s45_btn {
                    width: 325px;
                    height: 44px;
                    // border: 1px solid #000;
                    border-radius: 55px;
                    background-color: #000;
                    font-size: 0;
                    margin-top: 15px;
                }

                .s45_btn_cont {
                    width: 325px;
                    border: 2px solid #000;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 55px;
                    margin-top: -8px;
                    background-color: #fff;
                    cursor: pointer;

                    p {

                        font-weight: bold;
                        font-size: 20px;
                        text-decoration: underline;
                        text-underline-offset: 3px;
                    }
                }

                .s45_btn_cont:hover {
                    background-color: #FB6333;
                }


                .el-form-item {
                    height: 35px;
                    // border: 1px solid #000;
                    display: flex;
                    justify-content: center;

                    .el-form-item__label {
                        font-size: 20px;
                        color: #000;
                        font-weight: bold;
                    }

                    .el-input__inner {

                        background-color: #fff;
                        height: 35px;
                        text-align: center;
                        border: none;
                        border-bottom: 2px solid #000;
                        border-radius: 0px;
                        width: 250px;
                        font-size: 18px;
                        text-align: center;
                        color: #000;

                    }
                }
            }

        }
    }
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}

.mySwiper2 {
    .swiper-button-next {
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        top: calc(50%);
        transform: translateY(-37%);
        right: 0;
        // background-color: #e2e1e1;
        opacity: 1;
        // transition: all .36s;
        color: #9f9f9f;
    }

    .swiper-button-next:after {
        font-size: 30px;
        // color: #9f9f9f;
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50%);

        transform: translateY(-50%);
    }

    .swiper-button-prev {

        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        top: calc(50%);
        transform: translateY(-37%);
        // right: calc(50% + 15px);
        left: 0;
        // background-color: #e2e1e1;
        opacity: 1;
        // transition: all .36s;
        color: #9f9f9f;
    }

    .swiper-button-prev:after {
        font-size: 30px;

        position: absolute;
        left: calc(50% - 10PX);
        top: calc(50%);

        transform: translateY(-50%);
    }
}



.mySwiper4 {
    .swiper-button-next {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        top: calc(50%);
        transform: translateY(-50%);

        transform: translateX(20px);
    }

    .swiper-button-next:after {
        font-size: 30px;
        color: #9f9f9f;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 7px);
    }

    .swiper-button-prev {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        top: calc(50%);
        transform: translateY(-50%);

        transform: translateX(-20px);

    }

    .swiper-button-prev:after {
        font-size: 30px;
        color: #9f9f9f;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 7px);
        // transform: translateX(-50%);
    }
}


.mySwiper5 {
    .swiper-button-next {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;

    }

    .swiper-button-next:after {
        font-size: 30px;
        color: #9f9f9f;
        position: absolute;
        top: calc(50%);
        transform: translateY(-50%);
        left: calc(50% - 10px);
        // transform: translateX(-50%);
    }

    .swiper-button-prev {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        // background-color: rgb(242, 243, 244);
        // position: relative;
    }

    .swiper-button-prev:after {
        font-size: 30px;
        color: #9f9f9f;

        position: absolute;
        top: calc(50%);
        transform: translateY(-50%);
        right: calc(50% - 10px);
        // transform: translateX(-50%);
    }
}

.mySwiper1 {
    .swiper-button-next {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;
        border-radius: 50%;
        top: calc(50% - 25px);

    }

    .swiper-button-next:after {
        font-size: 30px;
        color: #9f9f9f;

        position: absolute;
        left: calc(50%);
        top: calc(50% - 15px);
        transform: translateX(-50%);
    }

    .swiper-button-prev {
        display: block;
        width: 44px !important;
        height: 44px;
        // border: 1px solid #000;s
        border-radius: 50%;
        top: calc(50% - 25px);
        // background-color: rgb(242, 243, 244);
        // position: relative;
    }

    .swiper-button-prev:after {
        font-size: 30px;
        color: #9f9f9f;

        position: absolute;
        left: calc(50%);
        top: calc(50% - 15px);
        transform: translateX(-50%);
        // transform: translateX(-50%);
    }
}


// @font-face {
//   /* 给引入的字体包起个名字 */
//   font-family: rossoYahei;
//   /* 引入字体文件 */
//   src: url(../img/msyh.ttc);

// }

@font-face {
    /* 给引入的字体包起个名字 */
    font-family: rossoNoto;
    /* 引入字体文件 */
    src: url(../img/rossoNoto.ttf);

}

// wlh
.section_inp {
    width: 100%;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;
    background-color: rgba(245, 232, 210, 1);

    .s_inp_left {
        width: 644px;
        height: 243px;
        // border: 1px solid #000;
        border: 4px solid rgba(176, 142, 93, 1);
        border-left: none;
        display: flex;
        align-items: center;
        border-radius: 0 122px 122px 0;
        background-color: #FFF7E9;



        .img_shou {
            width: 328px;
            height: 408px;
            // border: 1px solid #000;
        }

        .img_text {
            width: 220px;
            // margin-left: 10px;
        }

        div {
            width: 240px;
            height: 144px;

            .s_left_p1 {
                font-size: 36px;
                // font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
                font-weight: bold;
                color: #FFFFFF;
                letter-spacing: 7px;
                // text-stroke: 5px #B08E5D;
                text-shadow: 1px 1px 10px #B08E5D;
                -webkit-text-stroke: 2px #B08E5D;
            }
        }
    }

    .s_inp_right {
        width: 752px !important;
        height: 243px;
        // border: 1px solid #000;
        text-align: left;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-form {
            width: 705px;
            // border: 1px solid red;
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

        }

        .el-form-item {
            // border: 1px solid #000;
            // width: 350px;
            margin-right: 0;
        }

        .el-form-item__label {
            font-size: 20px;

            font-weight: bold;
            color: #000000;
            line-height: 36px;
        }

        .el-input__inner {
            background-color: rgba(245, 232, 210, 1);
            border: none;
            border-bottom: 2px solid #000;
            border-radius: 0px;
            width: 200px;
            font-size: 18px;
            text-align: center;
            color: #000;
        }

        .el-select-dropdown__list {
            text-align: center;
        }

        .elitem2 {
            input {
                width: 180px;
                height: 40px;
                background-color: #fff;
                border-radius: 24px;
                padding: 0 30px;
                border: 2px solid #000;
            }
        }

        .yuyue {
            width: 298px;
            height: 44px;
            background-image: url(../img/Group\ 222.png);
            background-position: 50%;
            background-size: 100%;
            margin-left: 33px;
            background-repeat: no-repeat;
        }
    }
}

.section8 {
    width: 1260px;
    // height: 900px;
    // border: 1px solid #000;
    margin: 0px auto 0;

    .s8_cont {
        display: flex;
        justify-content: center;
        align-items: center;

        .s8_left {
            width: 385px;
            height: 485px;
            // border: 1px solid blue;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .s8l_top {
                // width: 555px;
                // height: 98px;
                text-align: left;
                font-size: 23px;

                font-weight: bold;
                color: #000000;
                line-height: 36px;
                position: relative;


                .s81_line {
                    width: 554px;
                    height: 2px;
                    display: block;
                    background-color: #000;
                    position: absolute;
                    transform: rotate(-13deg);
                    top: 50px;
                    left: -5px;
                }
            }

            .s8_p1 {
                font-size: 18px;

                font-weight: 400;
                color: #000000;
                line-height: 30px;
                text-align: left;
                margin-top: 20px;
                text-align: justify;
            }

            .s8_p2 {
                margin-top: 30px;
                font-size: 18px;

                font-weight: 400;
                color: #000000;
                line-height: 30px;
                // text-align: left;
                text-align: justify;
            }
        }

        .s8clientBox {
            width: 620px;
            height: 312px;
            border-bottom: 2px solid #000;
            overflow: hidden;

            .s8banner {
                width: 6000px;
                display: flex;
                transition: all 0.6s;
            }
        }

        .s8_right {
            width: 620px;
            // height: 631px;
            border: 2px solid #000;
            border-radius: 20px;
            background-color: #fff;
            margin-left: 70px;

            .s8_bot {
                width: 620px;
                height: 94px;
                // border: 1px solid #000;
                // background-color: #666666;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .s8pre {
                    width: 55px;
                    height: 53px;
                    background-image: url(../img/homepage-折叠部分切图/zuobegin.png);
                    background-position: 50%;
                    background-size: 100%;
                    margin-left: 20px;
                    background-repeat: no-repeat;
                }

                .s8nex {
                    width: 55px;
                    height: 53px;
                    background-image: url(../img/homepage-折叠部分切图/youbegin.png);
                    background-position: 50%;
                    background-size: 100%;
                    margin-right: 20px;
                    background-repeat: no-repeat;
                }

                .s8name {
                    p {
                        height: 34px;
                        font-size: 22px;
                        font-family: rossoNoto;
                        font-weight: bold;
                        color: #000000;
                        line-height: 32px;
                    }
                }

                .s8pre:hover {

                    background-image: url(../img/homepage-折叠部分切图/zuohover.png);
                }

                .s8nex:hover {
                    background-image: url(../img/homepage-折叠部分切图/youhover.png);
                }
            }

            .s8r_top {
                width: 620px;
                height: 318px;
                // border: 1px solid #000;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-between;

                padding: 17px 10px;
                box-sizing: border-box;
                align-items: center;



                .s8img1 {
                    width: 339px;
                    height: 286px;
                    margin-right: 5px;
                    border-radius: 5px;
                    margin-top: -5px;
                    // border: 1px solid #000;
                }

                .s8img2 {
                    width: 252px;
                    height: 109px;
                    border-radius: 5px;
                    margin-top: -5px;
                    // border: 1px solid #000;
                }

                .s8img3 {

                    width: 252px;
                    height: 168px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    // border: 1px solid #000;
                }

            }

            .s8r_top:nth-of-type(1) {
                .s8img1 {
                    background-image: url(../img/collection/caoyang/caoyang1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/caoyang/caoyang2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/caoyang/caoyang3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(7) {
                .s8img1 {
                    background-image: url(../img/collection/caoyang/caoyang1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/caoyang/caoyang2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/caoyang/caoyang3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(2) {
                .s8img1 {
                    background-image: url(../img/collection/jiayu/jiayu1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/jiayu/jiayu2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/jiayu/jiayu3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(3) {
                .s8img1 {
                    background-image: url(../img/collection/matti/matti1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/matti/matti2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/matti/matti3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(4) {
                .s8img1 {
                    background-image: url(../img/collection/qingyang/qingyang1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/qingyang/qingyang2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/qingyang/qingyang3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(5) {
                .s8img1 {
                    background-image: url(../img/collection/Ruiling/ruiling1.png);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/Ruiling/ruiling2.png);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/Ruiling/ruiling3.png);
                    background-position: 50%;
                    background-size: 100%;
                }
            }

            .s8r_top:nth-of-type(6) {
                .s8img1 {
                    background-image: url(../img/collection/yaosichen/yaosichen1.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img2 {
                    background-image: url(../img/collection/yaosichen/yaosichen2.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }

                .s8img3 {
                    background-image: url(../img/collection/yaosichen/yaosichen3.jpg);
                    background-position: 50%;
                    background-size: 100%;
                }
            }


        }
    }
}



.shilineBox {
    width: 100%;
    height: 200px;
    // border: 1px solid #000;
    position: relative;

    .rLine {
        width: 571px;
        height: 2px;
        background-color: #000;
        position: absolute;
        right: 0;
        top: 45px;
    }

    .cLine {
        width: 2px;
        height: 157px;
        background-color: #000;
        position: absolute;
        right: 45px;
    }
}

@font-face {
    /* 给引入的字体包起个名字 */
    font-family: rossoFont;
    /* 引入字体文件 */
    src: url(../img/rosso.ttf);

}




.el-collapse {
    border-bottom: none;
}

.el-collapse-item__wrap {
    border-bottom: none;
}

.s34_cont {
    width: 1200px;
    height: 1015px;
    // border: 1px solid red;
    margin: 0 auto;
    padding-top: 50px;

    .mySwiper2 {
        overflow: hidden;
        position: relative;
        padding-bottom: 30px;

        // border: 1px solid #000;
        .swiper-pagination {
            bottom: -5px;
        }


        .swiper-slide {
            width: 1167px;
            height: 865px;
            // border: 1px solid hotpink;
            // background-color: #5F9DF7;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;



            .s34item {
                width: 355px;
                height: 402px;
                // border: 1px solid #000;
                text-align: center;
                margin: 0 15px;
                border-radius: 10px;
                box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
                cursor: pointer;
                background: linear-gradient(180deg, #5F9DF7 0%, #FFFFFF 85%);

                .s34_p1 {
                    font-size: 30px;
                    font-family: rossoFont;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 63px;
                    // margin-top: 17px;
                }

                img {
                    width: 316px;
                    height: 190px;
                    border-radius: 10px;
                    border: 3px solid #CFCFCF;

                    object-fit: cover;
                }

                .s34_p2 {
                    margin-top: 10px;
                }

                .s34_p2,
                .s34_p3 {
                    font-size: 20px;

                    font-weight: 400;
                    color: #000000;
                    line-height: 33px;
                }

                .s34_p3 {
                    font-weight: bold;
                    white-space: nowrap;
                }
            }
        }
    }
}


.s33Box {
    width: 1200px;
    height: 888px;

    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0px auto;
    position: relative;
    overflow: hidden;
    padding-top: 80px;
    // border: 1px solid #000;


    .s33titleTop {
        width: 508px;
        height: 83px;
        border: 2px solid #000;
        border-bottom: none;
        border-left: none;
        border-right: none;
        top: 52px;
        right: calc(50% - 254px);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background-color: #fff;

        .s33titleBox {
            width: 480px;
            display: flex;
            justify-content: space-between;
            height: 60px;
            font-size: 24px;

            font-weight: bold;
            color: #000000;
            border: 2px solid #000;
            border-radius: 40px;
            position: relative;


            p {
                width: 240px;
                height: 60px;
                line-height: 60px;
                border-radius: 20px 20px 0 0;
                text-align: center;
                // border: 2px solid #000;
                border-bottom: none;
                border-radius: 40px;
                position: absolute;
                background-color: transparent;
            }

            p:nth-of-type(3) {
                right: 0;
            }

            .s33blue {
                width: 240px;
                height: 58px;
                background-color: #5f93ff;
                position: absolute;
                border: 2px solid #000;
                transition: all 0.3s;
                top: -1px;
                left: -1px;

            }
        }
    }


    .s33btnBox {
        width: 354px;
        height: 58px;
        // border: 1px solid #000;
        position: absolute;
        bottom: 138px;
        right: 38px;
        display: flex;
        justify-content: space-between;
        display: none;

        div {
            height: 58px;
        }

        .s33pre {
            width: 58px;
            height: 58px;
            // border: 1px solid red;
            background-image: url(../img/homepage-折叠部分切图/zuobegin.png);
            background-position: 50%;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .s33nex {
            width: 58px;
            height: 58px;
            // border: 1px solid red;
            background-image: url(../img/homepage-折叠部分切图/youbegin.png);
            background-position: 50%;
            background-size: 100%;
            background-repeat: no-repeat;
        }

    }

    .s33pre:hover {
        background-image: url(../img/homepage-折叠部分切图/zuohover.png);
    }

    .s33nex:hover {
        background-image: url(../img/homepage-折叠部分切图/youhover.png);
    }

    .s33banner {
        width: 2152px;

        // border: 1px solid red;
        display: flex;
        transition: all .56s;

    }

    .s33clientBox {
        width: 1076px;
        height: 726px;
        border: 2px solid #000;
        border-radius: 10px;
        margin: 10px auto;
        background-color: #fff;
        overflow: hidden;
    }

    .s33Cont {
        width: 1076px;
        height: 993px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;



        .s33item:nth-of-type(1) {
            margin-top: -160px;
        }

        .s33item:nth-of-type(2) {
            margin-top: -160px;
        }

        .s33item:nth-of-type(3) {
            margin-top: -160px;
        }

        .s33item:nth-of-type(4) {
            margin-top: -160px;
        }

        .s33item:nth-of-type(5) {
            margin-top: -520px;
        }

        .s33item:nth-of-type(6) {
            margin-top: -520px;
        }

        .s33item:nth-of-type(7) {
            margin-top: -520px;
        }

        .s33item:nth-of-type(8) {
            margin-top: -520px;
        }

        .s33item {
            width: 253px;
            height: 291px;
            // border: 1px solid #000;
            // margin: 0 20px;
            cursor: pointer;
            text-align: center;

            .s33p1 {
                font-size: 24px;

                font-weight: bold;
                color: #FB6333;
                line-height: 36px;
            }

            .s33p2,
            .s33p3 {
                font-size: 18px;

                font-weight: 400;
                color: #000000;
                line-height: 28px;
            }

            .s33p2 {
                width: 193px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0 auto;
            }

            .s33imgBox {
                width: 193px;
                height: 202px;
                // border: 1px solid #000;
                position: relative;


                img {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: calc(50% - 67px);

                }

                .s33coverImg {
                    width: 101%;
                    left: calc(50% - 68px);
                }
            }
        }
    }
}

.s33bottom {
    width: 430px;
    height: 82px;
    border-bottom: 2px solid #000;
    background-image: url(../img/homepage-折叠部分切图/Group\ 235.png);
    background-position: 50%;
    background-size: 95%;
    background-repeat: no-repeat;
    border-radius: 0 0 40px 40px;
    position: absolute;
    bottom: 109px;
    cursor: pointer;
    left: calc(50% - 215px);
    background-color: #fff;
}





.s33Cont2 {
    // background-image: url(../img/homepage-折叠部分切图/Union.png);
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
}



.s33Cont1 {

    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.s3_cont2 {
    width: 100%;
    height: 500px;
    // border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    .swiper-pagination {
        bottom: -5px;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        // text-align: center;
        font-size: 18px;
        // background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .s3_cont2_item2 {
        width: 373px;
        height: 367px;
    }

    .s3_cont2_item {
        width: 360px;
        height: 367px;
        // border: 1px solid #000;
        margin: 0 13px;
        background-image: url(../img/homepage-折叠部分切图/Group\ 209.png);
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        color: #000;
        position: relative;

        .s3c2rig {
            margin: 35px 20px 0 60px;
        }

        p {
            font-size: 22px;

            font-weight: bold;
            line-height: 28px;
            margin-bottom: 35px;
            // text-align: right;

        }

        ul {
            list-style: inside;
            margin-top: -5px;
        }

        li {
            font-size: 20px;

            font-weight: 400;

        }
    }
}

.s3c2bot {
    display: flex;
    position: absolute;
    bottom: 70px;
    left: 50px;

    div {
        width: 140px;
        height: 32px;
        // border: 1px solid #000;
        cursor: pointer;
    }

    .s3c2botleft {
        background-position: 50%;
        background-size: 100%;
        background-image: url(../img/homepage-折叠部分切图/Group\ 189.png);
    }

    .s3c2botright {
        background-position: 50%;
        background-size: 100%;
        background-image: url(../img/homepage-折叠部分切图/Group\ 194.png);
        margin-left: 15px;
    }
}



.s3_cont1 {
    height: 532px;
    display: flex;
    flex-wrap: wrap;
    // border: 1px solid red;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    font-size: 0;
    padding-top: 30px;

}

.s31_btn {
    width: 274px;
    height: 56px;
    background: #5F9DF7;
    border-radius: 35px;
    border: 2px solid #000;
    line-height: 56px;
    text-align: center;
    margin: 35px auto;
    font-size: 22px;
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    cursor: pointer;
}

.s31_item {
    width: 500px;
    height: 240px;
    border: 2px solid #000;
    margin: 0 10px;
    border-radius: 22px;
    background-position: 50%;
    background-size: 100%;
    position: relative;
}

.s31_item:nth-child(1) {
    background-image: url(../img/cover1.jpg);
}

.s31_item:nth-child(2) {
    background-image: url(../img/cover2.jpg);
}

.s31_item:nth-child(3) {
    background-image: url(../img/cover3.jpg);
}

.s31_item:nth-child(4) {
    background-image: url(../img/cover4.jpg);
}

.cover_white {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);

    border-radius: 20px;
    overflow: hidden;

}

.cover_white:hover {
    background-color: none;

    background: linear-gradient(180deg, rgba(95, 157, 247, 0.8), rgba(130, 177, 244, 0.8)54%, rgba(255, 247, 233, 0.8) 100%);
}

.cover_white:hover .cover_cont2 {
    display: block;
}

.cover_white:hover .cover_cont1 {
    display: none;
}

.cover_cont1 {
    width: 266px;
    height: 116px;
    font-size: 24px;
    // border: 1px solid #000;
    color: #000;
    margin: 23px 0 0 30px;

    .cover_cont1_p1 {

        font-weight: bold;

    }

    .cover_cont1_p2 {
        line-height: 26px;
        font-family: rossoNoto;
        font-size: 20px;
    }
}

.cover_cont2 {
    width: 378px;
    height: 110px;

    // border: 1px solid #000;
    text-align: center;
    font-size: 24px;

    font-weight: bold;
    color: #fff;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 189px);

    letter-spacing: 1px;
    display: none;

    p {
        line-height: 40px;
    }
}




.el-collapse-item__arrow {
    // border: 1px solid #000;
    font-size: 30px;
    margin-right: 30px;
}

.xieline {
    width: 127px;
    height: 2px;
    background-color: black;
    transform: rotate(-9deg);
    transform-origin: 50%;
}

.xieP1 {
    // width: 221px;
    margin: 0 20px 0 30px;
    font-size: 28px;


    color: #000000;
    line-height: 41px;
}

.xieP2 {
    margin: 0 20px;
    height: 40px;
    font-size: 28px;
    font-family: rossoNoto;

    color: #000000;
    line-height: 41px;
}

.dbimgbox {
    position: relative;
    width: 196px;
    height: 196px;
    overflow: hidden;
    // border: 1px solid #000;

    .zixun1 {
        width: 294px;
        height: 196px;
        // border: 1px solid #000;
        position: absolute;
        top: 0;
        left: calc(50% - 147px);
        object-fit: cover;
    }

    .coverZixun1 {
        width: 196px;
        height: 196px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.section7 {
    width: 1260px;
    height: 630px;
    margin: 50px auto 0px;



    .s7_cont {
        width: 1260px;
        // height: 500px;
        overflow: hidden;
        // border: 1px solid blue;
        margin-top: 70px;
    }

    .s7_line {
        width: 4608px;
        // border: 1px solid red;
        margin: 0 0 50px 0;
        font-size: 0;
        // text-align: left;
        display: flex;
        animation: move 30s linear infinite;

        img {
            width: 88px;
            height: 88px;
            // border: 1px solid #000;
            margin: 0 28px;
            border-radius: 50%;
        }
    }

    .s7_banner2 {
        // margin-left: -2560px;
        animation: move2 40s linear infinite;
    }

    .s7_banner3 {
        animation: move3 25s linear infinite;
    }
}

@keyframes move {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-2304px);
    }
}

@keyframes move2 {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-2304px);
    }
}

@keyframes move3 {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-2304px);
    }
}

.section6 {
    width: 1260px;
    ;
    // border: 1px solid blue;
    margin: 200px auto 0;

    .s6_cont {
        width: 1260px;
        // height: px;
        // border: 1px solid #000;
        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .s6_left {
            width: 560px;
            // height: 500px;
            // border: 1px solid #000;

            .swiper {
                width: 417px;
                height: 378px;
                margin-top: 40px;
            }

            .mySwiper3 {


                .swiper-slide {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    font-weight: bold;

                    img {
                        width: 396px;
                        height: 238px;
                        // border: 1px solid #000;
                        margin-top: 20px;
                    }

                    p {
                        width: 356px;
                        // height: 87px;
                        // border: 1px solid #000;
                        font-size: 20px;

                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                        margin-top: 10px;
                        // margin-bottom: 20px;
                    }
                }

                .swiper-pagination {
                    bottom: -40px;
                }

                .swiper-button-prev {
                    left: -50px;
                    color: #a4a0a0;
                }

                .swiper-button-next {
                    right: -50px;
                    color: #a4a0a0;
                }

                .swiper-slide:nth-child(1n) {
                    // background-color: rgb(206, 17, 17);
                    background-image: url(../img/rongkuang.png);
                    background-position: 50%;
                    background-size: 100%;
                }

             
            }
        }

        .s6_right {
            width: 661px;
            // height: 500px;
            // border: 1px solid #000;
            text-align: left;

            ul {
                width: 100%;
                list-style: inside;
                height: 280px;
                // border: 1px solid #000;
                margin-top: 50px;
                transition: all .36s;
                // display: -webkit-box;

                // -webkit-box-orient: vertical;

                // -webkit-line-clamp: 7;

                overflow: hidden;

                li {
                    height: 40px;
                    font-size: 20px;

                    font-weight: 400;
                    color: #000000;
                    line-height: 40px;
                }
            }

            #moreHonors {
                width: 214px;
                height: 48px;
                margin-top: 40px;
            }
        }


    }
}



.section5 {
    // width: 1444px;
    // border: 1px solid blue;
    margin: 200px auto 0;
    position: relative;
}

#s5banner {
    width: 1444px;
    // border: 1px solid red;
    height: 500px;
    // margin: 0 auto;
    overflow: hidden;


    .prev {
        width: 56px;
        height: 97px;
        position: absolute;
        top: calc(50% - 75px);
        right: -70px;
        border-radius: 30px;
        background: #D9D9D9;
        cursor: pointer;

        div {
            width: 0;
            height: 0;
            border: 14px solid transparent;
            border-right-color: #fff;
            // margin: 0 auto;
            margin-left: 4px;
        }
    }

    .prev:hover {
        background: #5F9DF7;
    }

    .next:hover {
        background: #5F9DF7;
    }

    .next {
        width: 56px;
        height: 97px;
        position: absolute;
        top: calc(50% + 30px);
        right: -70px;
        border-radius: 30px;
        background: #D9D9D9;
        cursor: pointer;

        div {
            width: 0;
            height: 0;
            border: 14px solid transparent;
            border-left-color: #fff;
            // margin: 0 auto;
            margin-left: 20px;
        }
    }
}

.s5_cont {

    width: 1260px;
    height: 600px;
    // border: 1px solid #000;
    margin: 0 auto;

    .s5_area {
        width: 1065px;
        height: 48px;
        // border: 1px solid #000;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;

        div {
            width: 100px;
            height: 43px;
            border: 2px solid #000;
            border-radius: 24px;
            font-size: 18px;
            white-space: nowrap;
            font-weight: 500;
            color: #000000;
            line-height: 43px;
            text-align: center;
            background-color: #fff;
            cursor: pointer;
        }
    }

    .s5_env {
        width: 1068px;
        display: flex;
        justify-content: space-between;
        margin: 40px auto 0;

        .s5_left {
            width: 782px;
            height: 450px;
            // border: 1px solid #000;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .s5_img1 {
                width: 436px;
                height: 201px;
                // border: 1px solid #000;
                border-radius: 10px;
                // background-image: url(../img/各校区门头图/上海.jpg);
                background-size: 100%;
                background-position: 50%;
                transition: all .6s;
                border: 2px solid #000;
            }

            .s5_img2 {
                width: 532px;
                height: 225px;
                // border: 1px solid #000;
                // background-image: url(../img/各校区门头图/上海.jpg);
                background-size: 100%;
                background-position: 50%;
                border-radius: 10px;
                transition: all 1s;
                border: 2px solid #000;
            }

            .s5_img3 {
                width: 235px;
                height: 225px;
                // border: 1px solid #000;
                // background-image: url(../img/各校区门头图/上海.jpg);
                background-size: 200%;
                background-repeat: no-repeat;
                background-position: 50%;
                border-radius: 10px;
                transition: all 1s;
                border: 2px solid #000;
                // background-repeat: no-repeat;
            }

            .s5_zuoshang {
                width: 314px;
                height: 165px;
                // border: 1px solid #000;
                position: relative;
                margin: 16px 0 0 16px;


                .s5_p1 {
                    font-size: 26px;

                    font-weight: bold;
                    color: #000000;
                    line-height: 39px;
                    text-align: left;

                }

                .s5_p2 {
                    font-size: 18px;
                    font-family: rossoNoto;
                    font-weight: 400;
                    color: #000000;
                    text-align: left;
                }

                .s5_p3 {
                    width: 240px;
                    font-size: 16px !important;

                    font-weight: 400;
                    color: #000000;
                    // line-height: 30px;
                    text-align: left;
                    margin-top: 40px;
                    margin-left: 60px;
                }

                .s5_line {
                    width: 280px;
                    height: 2px;
                    background-color: #000;
                    transform: rotate(-22deg);
                    position: absolute;
                    top: 60px;
                }
            }
        }

        .s5_right {
            width: 274px;
            height: 438px;
            // border: 1px solid #000;
            background-repeat: no-repeat;
            background-size: 300%;
            background-position: 50%;
            border-radius: 10px;
            transition: all 1s;
            border: 2px solid #000;
            object-fit: cover;
        }
    }

}

.bannerAfterP1 {
    height: 30px;
    // border: 1px solid #000;
    font-size: 26px;
    line-height: 30px;
    // font-size: 2.05vw;
    color: #FB6333;
    margin-bottom: 3px;
    font-weight: bold;

}

.bannerAfterP2 {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    // line-height: 34px;

}

.bannerAfter {
    width: 1070px;
    list-style: none;
    // border: 1px solid #000;
    display: flex;
    margin: -176px auto 0px;
    justify-content: center;
    position: relative;

    li {
        max-width: 179px;
        min-width: 120px;
        height: 109px;
        // border: 1px solid #000;
        margin: 0 12px;
    }
}

.testImg {
    width: 100%;
    height: 100%;
}

.homeMain {

    // width: 1440px;
    // width: 100%;
    // max-width: 1600px;
    // min-width: 1200px;
    // height: 668px;
    // max-height: 1093px;
    // border: 1px solid red;
    margin: 0px auto 0;
    // background-image: url(../img/Bannercha.png);
    // border: 1px solid #000;
    background-size: 100%;
    // background-size:cover;
    position: relative;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .bannerImg {
        width: 100%;
        height: 100%;
    }

}

.homebannerBox {
    width: 85%;
    // height: 32.81%;
    border: 20px solid #000;
    position: absolute;
    left: calc(50% - 42.5%);
    top: calc(17%);
    // top: 94px;
    // transform: translateY(-50%);
    // top: 297px;
    // background-image: url(../img/10001.jpg);
    background-position: 50% 50%;
    background-size: 126%;
    background-repeat: no-repeat;
    // border: 1px solid red;
    cursor: pointer;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.homebannerBox::after {
    content: "";
    width: 40px;
    // height: 170px;
    height: 68%;
    background-color: #000;
    position: absolute;
    // bottom: -189px;
    bottom: -65%;
    left: 9%;
}

.homebannerBox::before {
    content: "";
    width: 40px;
    // height: 170px;
    height: 68%;
    background-color: #000;
    position: absolute;
    bottom: -65%;
    right: 9%;
}

.aboutS4 {
    position: relative;
    // border: 1px solid #000;
}

.pos_num {
    width: 600px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 300px);
    cursor: pointer;

    div {
        width: 100px;
        text-align: center;
        font-size: 20px;

        font-weight: 400;
    }
}

.section4 {
    width: 1260px;
    margin: 140px auto 0;
}

.s4_cont {
    width: 100%;
    height: 507px;
    // border: 1px solid #000;
    display: flex;
    justify-content: center;

    .s4_left {
        width: 532px;
        height: 550px;
        // border: 1px solid #000;
        margin-right: 60px;
        cursor: pointer;

        .s4_left_p2 {
            height: 70px;
            display: -webkit-box;
            /* -webkit-box-orient 设置弹性伸缩盒子中内容的排列方式，vertical从上到下垂直排列内容 */
            -webkit-box-orient: vertical;

            /* 设置行数 */
            -webkit-line-clamp: 2;

            overflow: hidden;

        }


        .dbimgboxFirst {
            width: 532px;
            height: 226px;
            position: relative;
            overflow: hidden;
            // border: 1px solid #000;
            img:nth-of-type(1) {
                // object-fit: cover;
                width: 532px;
                position: absolute;
                top: 0;
                z-index: 1;
            }

            img:nth-of-type(2){
                width: 532px;
                height: 226px;
                // border: 1px solid #000;
                position: absolute;
                z-index: 2;
            }
        }



        p {
            text-align: left;
            width: 210px;
            height: 51px;
            font-size: 20px;

            font-weight: 400;
            color: #000000;
            line-height: 32px;
            margin-top: 45px;
        }

        h2 {
            line-height: 35px;
            text-align: left;

            color: #5F9DF7;
        }

        div {
            width: 530px;
            height: 118px;
            font-size: 20px;

            font-weight: 400;
            color: #000000;
            line-height: 35px;
            text-align: left;
            margin-top: 15px;
        }

    }

    .s4_right {
        width: 600px;
        height: 550px;
        // border: 1px solid #000;

        .el-carousel__indicator--horizontal {
            padding: 0;
            // position: relative;
        }

        .el-carousel__indicator--horizontal:nth-of-type(1) {
            position: relative;
        }

      

        .el-carousel__indicator--horizontal.is-active .el-carousel__button {
            background-color: #FB6333;
            height: 4px;
        }

        .el-carousel__button {
            background-color: #D9D9D9;
            width: 100px;
            height: 3px;

        }

        .s4_right_item {
            height: 200px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            cursor: pointer;

            // img {
            //   // width: 196px;
            //   // height: 196px;
            //   // border: 1px solid #000;
            // }

            .h3 {
                width: 386px;
                height: 68px;
                font-size: 18px;

                font-weight: 400;
                color: #000000;
                line-height: 32px;
                text-align: left;

                display: -webkit-box;
                /* -webkit-box-orient 设置弹性伸缩盒子中内容的排列方式，vertical从上到下垂直排列内容 */
                -webkit-box-orient: vertical;

                /* 设置行数 */
                -webkit-line-clamp: 2;

                overflow: hidden;


            }

            p {
                width: 328px;
                height: 51px;
                font-size: 18px;

                font-weight: 400;
                color: #000000;
                line-height: 29px;
                text-align: left;
            }
        }
    }
}

.section_title {
    width: 476px;
    // border: 1px solid #000;
    margin: 0 auto;
    margin-bottom: 60px;
    position: relative;

    .section_cn {
        // height: 51px;
        font-size: 32px;

        font-weight: bold;
        color: #000000;
        // line-height: 51px;
        letter-spacing: 1px;
    }

    .section_en {
        font-size: 20px;
        font-family: rossoNoto;
        font-weight: 400;
        color: #000000;
        // line-height: 32px;
        letter-spacing: 1px;
    }

    .section_cn::after {
        content: "";
        width: 2px;
        height: 65px;
        background-color: #000;
        position: absolute;
        left: 0;
        top: -20px;
    }

    .section_cn::before {
        content: "";
        width: 2px;
        height: 65px;
        background-color: #000;
        position: absolute;
        right: 0;
        // : 0;
        bottom: -20px;
    }

    .section_en::before {
        content: "";
        width: 65px;
        height: 2px;
        background-color: #000;
        position: absolute;
        left: -10px;
        top: -10px;
    }

    .section_en::after {
        content: "";
        width: 65px;
        height: 2px;
        background-color: #000;
        position: absolute;
        right: -10px;
        bottom: -10px;
    }
}

.s3_cont_box {
    width: 100%;
    height: 710px;
    // border: 1px solid #000;
    // margin-bottom: 10px;
    box-sizing: border-box;
}

.card_bottom {
    width: 100%;
    height: 88px;
    box-sizing: border-box;
    border-bottom: 1px solid hotpink;
    border-left: 1px solid hotpink;
    border-right: 1px solid hotpink;
    border-radius: 0 0 10px 10px;
}

.section3 {
    width: 1200px;
    margin: 100px auto 0;
}

// 上半弧
.el-collapse-item__header {
    /* color: #fff; */
    // background-color: #313743 !important;
    /* position: relative; */
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: none;

    // background-color: rgba(255, 247, 233, 1);
    background-image: linear-gradient(to bottom, rgba(207, 225, 253), rgba(255, 247, 233));

    margin-bottom: 10px;
    border-radius: 20px 20px 0 0;
    height: 88px;
}

// 下半弧 ::v-deep 加上的话可以穿透 scoped
.el-collapse-item__content {
    text-align: left;
    // color: #fff;
    background-color: rgba(255, 247, 233, 1);
    padding-bottom: 0;
}

.section2 {
    // border: 1px solid #000;
    width: 100%;
    height: 249px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5E8D2;

    .s2_cont {
        width: 772px;
        height: 148px;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .s2_item {
            width: 115px;
            height: 148px;
            // border: 1px solid #000;

            img {
                width: 80px;
                // height: 68px;
                // border: 1px solid #000;
            }

            p {
                width: 115px;
                height: 30px;
                line-height: 30px;
                // border: 1px solid #000;
                text-align: center;
            }

            .s2_itemP1 {
                font-size: 18px;

            }

            .s2_itemP2 {
                font-size: 22px;
                font-weight: bold;

            }
        }
    }
}


.mySwiper1 {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: relative;
    // border: 1px solid #000;

    .swiper {
        position: relative;
        overflow: hidden;
    }

    .swiper-slide {
        width: 220px;
        height: 600px;
        // border: 1px solid #000;
        text-align: center;
        font-size: 18px;
        // background-color: hotpink;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-box-justify-content: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        margin-right: 20px;

        .swiper-pagination {
            bottom: 10px;
        }

        div {
            width: 200px;
            height: 270px;
            // margin-top: 54px;
            // border: 1px solid red;

            // background-color: greenyellow;
            img {
                width: 138px;
                height: 138px;
                // border: 1px solid #000;
                border-radius: 50%;
            }

            p {
                // width:220px;
                text-align: center;
                margin: 0 auto;
                white-space: nowrap;
                // border: 1px solid red;
                // font-size: 22px;
                font-size: 16px;
                color: #000000;
                font-weight: 400;
                letter-spacing: 1px;
                line-height: 30px;

                span {
                    font-weight: bold;
                    font-size: 26px;
                }
            }
        }
    }
}

.page {
    background-color: rgba(255, 247, 233, 1);
    position: relative;
    // border: 1px solid #000;
    // width: 100%;
    // height: 8063px;
    // overflow: hidden;
    // margin: 0 auto;
}

.section1 {
    width: 1260px;
    height: 850px;
    // border: 1px solid #000;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;


}

.s1_left_img {
    // width: 480px;
    height: 480px;
    cursor: pointer;
}

.s1_right {
    width: 720px;
    height: 800px;
    // border: 1px solid #000;

    .s1_title_p {
        height: 37px;
        // border: 1px solid #000;
        font-size: 22px;
        font-family: rossoNoto;
        font-weight: 400;
        color: #000;
        line-height: 37px;
    }
}

.s1_right_title {
    width: 100%;
    height: 138px;
    // border: 1px solid #000;
    overflow: hidden;
}

.s1_right_starBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 62px;
    align-items: center;
    color: #000;
}

.star {
    width: 30px;
    height: 30px;
    // border: 1px solid #000;
    margin: 0 20px;
}

.s1_banner_box {
    width: 680px;
    height: 600px;
    // border: 1px solid #000;
    margin: 0px auto;
    // position: relative;
    cursor: pointer;

    .swiper-pagination {
        // position: absolute;
        bottom: 10px;
    }
}

.s1_cname {
    // width: 220px;
    // border: 1px solid #000;
    // margin: 0 auto;
    margin-left: -10px;
    transform: translateX(-3px);
}

.newBanner {
    background-color: #21ADF4;
    width: 100%;
    // height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .newBanner_left {
        width: 62.6%;
        // height: 299px;
        height: 85%;
        // border: 1px solid #000;


        .newBanner_top {
            height: 16.9%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;

            p {

                font-size: 31px;
                font-weight: bold;
                color: #fff;

            }

            div {
                height: 100%;
                display: flex;
                align-items: center;
            }

            img {
                width: 75px;
                height: 63.7%;

                margin-left: 20px;
            }
        }



        .newBanner_title {
            display: flex;
            align-items: center;
            height: 48%;
            border-bottom: 2px solid #fff;
            overflow: hidden;

            p {
                line-height: 54px;
                font-size: 36px;
                font-weight: bold;
                color: #FFFFFF;
                letter-spacing: 2px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-align: left;
            }

        }

        .newBanner_bottom {
            // width: 100%;
            height: 24.6%;
            margin-top: 18px;

        }
    }


    .newBanner_right {
        width: 30.7%;
        height: 85%;
        border: 2px solid #fff;
        box-sizing: border-box;
        margin-left: 28px;
    }
}

.newBannerSlide {
    width: 100%;
    height: 100%;
    // border: 1px solid red;
}
</style>
