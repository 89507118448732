<template>
    <div id="app">
        <div id="coverBlack" @click="hidBlack()">
            <img src="" alt="" id="coverMainImg">
        </div>
        <div id="coverBlack2" @click="hidBlack2()">
            <div class="cover_inp_box" @click="eCoverBlack2($event)">
                <img class="cover_inp_left" src="./img/coicon3.png" alt="">
                <div class="cover_inp_right">
                    <div class="c_top">
                        <p class="p1">GO FOR ART</p>
                        <p class="p2"></p>
                        <div @click="hidBlack2()">
                            <p class="p3"></p>
                            <p class="p4"></p>
                        </div>
                    </div>
                    <div class="s_inp_right">
                        <el-form :inline="true" :label-position="labelPosition" label-width="150px" :model="formLabelAlign">
                            <el-form-item label="姓名：">
                                <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="电话：">
                                <el-input v-model="formLabelAlign.tel" placeholder="请输入电话号码"></el-input>
                            </el-form-item>
                            <el-form-item label="意向专业：">
                                <el-input v-model="formLabelAlign.major" placeholder="请输入意向专业"></el-input>
                            </el-form-item>
                            <el-form-item label="意向国家：" class="elitem2">
                                <el-select v-model="formLabelAlign.region2" placeholder="选择意向国家">
                                    <el-option label="英国" value="England"></el-option>
                                    <el-option label="美国" value="America"></el-option>
                                    <el-option label="日本" value="Japan"></el-option>
                                    <el-option label="新加坡" value="Singapore"></el-option>
                                    <el-option label="韩国" value="Korea"></el-option>
                                    <el-option label="欧洲" value="Europe"></el-option>

                                    <el-option label="其他" value="Other"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <div class="yuyue" @click="onSubmit()">
                                    <img src="./img/coicon2.png" alt="">
                                    <p>立即预约</p>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="c_bottom">
                        <p class="p2"></p>
                        <p class="p1">GO FAR WITH RoSSo</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav1_box navUi_hid">
            <nav id="nav1">
                <div class="appImg">
                    <router-link to="/" style="display: inline-block;" class="logoChange">
                        <div class="loGo"></div>
                        <img src="./img/Group 242.png" alt="" class="logo2">
                    </router-link>
                    <div class="logoRightBox">
                        <p class="logoP1 logoP2">累计圆梦学生 <span style="font-weight: bold;">16000+</span> </p>
                        <p class="logoP1 logoP12" style="font-weight: bold;">要OFFER，更要能力，伴你至就业</p>

                        <p class="logoP3">
                            <img src="./img/Vector.png" style="width: 27px;height: 27px;vertical-align: top;" alt=""><span
                                style="margin-left: 10px;color: #fb6333;">400-0999-530</span>
                        </p>
                    </div>
                </div>

                <div class="appNavBox" @mouseleave="leaveCourse">
                    <router-link to="/" class="nav_item borBot borRig">
                        <div class="nav_item_cn">首页</div>
                        <p class="nav_item_en">HOMEPAGE</p>
                    </router-link>
                    <router-link to="/gdzpj.html" class="nav_item borBot borRig borOne course_relative">
                        <div @mouseenter="mouseCourse">
                            <!-- <div class="nav_item_cn">作品集培训</div> -->
                            <div class="nav_item_cn">艺术课程</div>
                            <p class="nav_item_en">ART COURSE</p>
                        </div>
                        <div class="nav_line">

                        </div>
                    </router-link>

                    <div class="course_position" @mouseleave="leaveCourse" @click="clickCourse">

                        <router-link to="/PortfolioInfo/vip.html" class="nav_item course_item">
                            VIP院校计划
                        </router-link>
                        <router-link to="/PortfolioInfo/professional_portfolio.html" class="nav_item course_item">
                            专业作品集辅导
                        </router-link>
                        <router-link to="/promoting.html" class="nav_item course_item">
                            海外游学项目
                        </router-link>
                        <router-link to="/PortfolioInfo/art_management.html" class="nav_item course_item">
                            BTEC艺术课程
                        </router-link>
                        <router-link to="/PortfolioInfo/grand_master.html" class="nav_item course_item">
                            A-LEVEL课程
                        </router-link>
                        <router-link to="/PortfolioInfo/foundation.html" class="nav_item course_item">
                            GA&GD创意课程
                        </router-link>
                        <router-link to="/PortfolioInfo/aesthetics_promoting.html" class="nav_item course_item">
                            AP课程
                        </router-link>
                        <router-link to="/PortfolioInfo/RossoCollege.html" class="nav_item course_item">
                            RoSSo College
                        </router-link>
                    </div>
                    <router-link to="/teacher.html" class=" nav_item borBot borRig">
                        <div class="nav_item_cn">师资团队</div>
                        <p class="nav_item_en">ART TUTOR</p>
                    </router-link>
                    <router-link to="/school-0-0.html" class="nav_item borBot">
                        <div class="nav_item_cn">名校导航</div>
                        <p class="nav_item_en">ART SCHOOL</p>
                    </router-link>
                    <router-link to="/major.html" class="nav_item borTwo">
                        <div class="nav_item_cn">艺术专业</div>
                        <p class="nav_item_en">ART MAJOR</p>
                    </router-link>
                    <router-link to="/news.html" class="nav_item">
                        <div class="nav_item_cn">艺术资讯</div>
                        <p class="nav_item_en">ART CASE</p>
                    </router-link>
                    <router-link to="/promoting.html" class="nav_item">
                        <div class="nav_item_cn">背景提升</div>
                        <p class="nav_item_en">PROMOTING</p>
                    </router-link>

                    <a href="http://music.rossoarts.com/" target="_blank" class="nav_item">
                        <div class="nav_item_cn">音乐留学</div>
                        <p class="nav_item_en">RoSSo MUSIC</p>
                    </a>
                </div>
            </nav>
        </div>


        <nav class="navUi_hid" id="nav2" @mouseleave="leaveCourse2">


            <div class="nav2_box" id="nav2_box">
                <div class="nav2_left">

                    <router-link to="/" style="width: auto;">
                        <!-- <img src="./img/logo1.png" alt=""  @click=""> -->
                        <div class="n2_logo"></div>
                    </router-link>
                    <div class="n2_text_box">
                        <p class="n2_p1">洛素国际艺术教育</p>
                        <p class="n2_p2">RoSSo ARTS & DESIGN EDUCATION</p>
                        <p class="n2_p3">联系我们：400-0999-530</p>
                    </div>
                </div>
                <div class="nav2_right">
                    <router-link to="/">
                        <p class="a_p_cn">首页</p>
                        <p class="a_p_en">HOMEPAGE</p>
                    </router-link>

                    <router-link to="/gdzpj.html" class="course_relative2">
                        <div @mouseenter="mouseCourse2">
                            <!-- <p class="a_p_cn">作品集培训</p> -->
                            <p class="a_p_cn">艺术课程</p>
                            <p class="a_p_en">ART COURSE</p>
                        </div>
                    </router-link>



                    <router-link to="/teacher.html">
                        <p class="a_p_cn">师资团队</p>
                        <p class="a_p_en">ART TUTOR</p>
                    </router-link>
                    <router-link to="/school-0-0.html">
                        <p class="a_p_cn">名校导航</p>
                        <p class="a_p_en">ART SCHOOL</p>
                    </router-link>
                    <router-link to="/major.html">
                        <p class="a_p_cn">艺术专业</p>
                        <p class="a_p_en">ART MAJOR</p>
                    </router-link>
                    <router-link to="/news.html">
                        <p class="a_p_cn">艺术资讯</p>
                        <p class="a_p_en">ART CASE</p>
                    </router-link>

                    <router-link to="/promoting.html">
                        <p class="a_p_cn">背景提升</p>
                        <p class="a_p_en">PROMOTING</p>
                    </router-link>

                    <a href="http://music.rossoarts.com/" target="_blank">
                        <p class="a_p_cn">音乐留学</p>
                        <p class="a_p_en">RoSSo MUSIC</p>
                    </a>

                </div>
            </div>

            <div class="position2_relative">
                <div class="course_position2" @mouseleave="leaveCourse2" @click="clickCourse2">
                    <router-link to="/PortfolioInfo/vip.html" class="nav_item course_item">
                        VIP院校计划
                    </router-link>
                    <router-link to="/PortfolioInfo/professional_portfolio.html" class="nav_item course_item">
                        专业作品集辅导
                    </router-link>
                    <router-link to="/promoting.html" class="nav_item course_item">
                        海外游学项目
                    </router-link>
                    <router-link to="/PortfolioInfo/art_management.html" class="nav_item course_item">
                        BTEC艺术课程
                    </router-link>
                    <router-link to="/PortfolioInfo/grand_master.html" class="nav_item course_item">
                        A-LEVEL课程
                    </router-link>
                    <router-link to="/PortfolioInfo/foundation.html" class="nav_item course_item">
                        GA&GD创意课程
                    </router-link>
                    <router-link to="/PortfolioInfo/aesthetics_promoting.html" class="nav_item course_item">
                        AP课程
                    </router-link>
                    <router-link to="/PortfolioInfo/RossoCollege.html" class="nav_item course_item">
                        RoSSo College
                    </router-link>
                </div>
            </div>
        </nav>

        <div class="fixInp navUi_hid">
            <div class="fix_right">
                <input class="fix_inp" type="text" v-model="formLabelAlign.name" placeholder="请输入姓名">
                <input class="fix_inp" type="text" v-model="formLabelAlign.tel" placeholder="请输入电话号码">
                <div class="fix_inp fix_sub" @click="onSubmit">
                    <p>点我提交领取</p>
                </div>
            </div>
        </div>

        <router-view />

        <div class="bgcColor navUi_hid">
            <div class="rootBot">
                <div class="bot_cont">
                    <div class="bot_numb">留学咨询：400-0999-530</div>
                    <div class="bot_title">
                        <p class="bot_cn">RoSSo 国际艺术教育</p>
                        <p class="bot_en">RoSSo Arts & Design Education</p>
                    </div>

                    <a href="javascript:void(0)" onclick="_MEIQIA('showPanel',{ groupToken: 'c88f871be5ec47bd52d093a69fccf897' })">
                        <div class="bot_online">
                            <img src="./img/Group 595.png" alt="">
                            <p>在线咨询</p>
                        </div>
                    </a>


                    <div class="bot_last">
                        <div class="bot_art">
                            <div class="art_left">艺术留学</div>
                            <div class="art_right">
                                <div class="m_li">
                                    <div class="li2" @mouseenter="mouse2(0)" style="font-weight: bold;">上海（总部）</div>
                                    <div class="li2" @mouseenter="mouse2(1)">北京</div>
                                    <div class="li2" @mouseenter="mouse2(2)">广州</div>
                                    <div class="li2" @mouseenter="mouse2(3)">深圳</div>
                                    <div class="li2" @mouseenter="mouse2(4)">杭州</div>
                                    <div class="li2" @mouseenter="mouse2(5)">南京</div>
                                    <div class="li2" @mouseenter="mouse2(6)">成都</div>
                                    <div class="li2" @mouseenter="mouse2(7)">武汉</div>
                                    <div class="li2" @mouseenter="mouse2(8)">西安</div>
                                    <div class="li2" @mouseenter="mouse2(9)">东京</div>
                                </div>
                                <div class="location">
                                    <div id="show2">上海徐汇区文定路208号A座301-302室(<a href="https://case.hongqivr.com/RoSSo/shanghai/" target="_blank" rel="nofollow">VR全景体验</a>)</div>
                                </div>
                            </div>
                        </div>
                        <div class="bot_art">
                            <div class="art_left">音乐留学</div>
                            <div class="art_right">
                                <div class="m_li">
                                    <div class="li" id="liFirst" @mouseenter="mouse1(0)" style="font-weight: bold;">上海（总部）
                                    </div>
                                    <div class="li" @mouseenter="mouse1(1)">北京</div>
                                    <div class="li" @mouseenter="mouse1(2)">深圳</div>
                                    <div class="li" @mouseenter="mouse1(3)">广州</div>
                                    <div class="li" @mouseenter="mouse1(4)">成都</div>
                                </div>
                                <div class="location">
                                    <div id="show1">上海徐汇区文定路208号A座301-302室</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="bot_art">
                            <div class="art_left">合作学校</div>
                            <div class="art_right">
                                <div class="m_li">
                                    <div class="li3 li" id="liFirst" @mouseenter="mouse3(0)" style="font-weight: bold;">上海
                                    </div>
                                    <div class="li3 li" @mouseenter="mouse3(1)">北京</div>
                                    <div class="li3 li" @mouseenter="mouse3(2)">深圳</div>
                                    <div class="li3 li" @mouseenter="mouse3(3)">合肥</div>

                                    <div class="li3 li" @mouseenter="mouse3(4)">内蒙古</div>


                                </div>
                                <div class="location">
                                    <div id="show3">上海外国语大学立泰学院剑桥国际中心：上海市虹口区大连西路550号</div>
                                    <div id="show4"
                                        style="text-align: left;margin-top: 8px;display: none;font-weight: bold;">
                                        橘郡国际学校深圳校区：深圳市宝安区石岩镇育才路8号</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="bot_art">
                            <div class="art_left">联系我们</div>
                            <div class="art_right join_us_box">
                                <div class="join_us">投诉建议 | advice@rossoarts.com</div>
                                <div class="join_us">加入我们 | hr@rossoarts.com</div>
                                <div class="join_us">商务合作邮箱 | mk@rossoarts.com</div>
                                <div class="join_us">商务合作微信 | ROSSOMKT</div>
                            </div>
                        </div>

                        <div class="code_last">
                            <img src="./img/logo1.png" alt="">
                            <!-- <img src="./img/Group 238.png" alt="">
                            <img src="./img/Group 239.png" alt=""> -->
                        </div>
                    </div>



                </div>
            </div>
            <!-- <img src="./img/ditu.png" alt="" class="ditu"> -->
        </div>

    </div>
</template>
<script>
import axios from 'axios';
// import "@/assets/css/kefu.js"
import "@/assets/css/lefthover.js"
import "@/assets/css/lefthover.css"
import "@/assets/css/app.css"
export default {
    data() {
        return {
            li1: ["上海徐汇区文定路208号A座301-302室", `一校：北京市朝阳区百子湾路苹果社区北区32号院6号楼88号|二校：海淀区海淀北二街8号中关村SOHO`, "深圳市福田区金田路东福华路北岗厦皇庭中心37楼", "广州市天河区天河北路233号中信广场7308室", "成都市锦江区成都国际金融中心IFS一号楼3203-3206室"],
            li2: [
                `上海徐汇区文定路208号A座301-302室(<a href="https://case.hongqivr.com/RoSSo/shanghai/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `一校：北京市朝阳区百子湾路苹果社区北区32号院6号楼88号(<a href="https://case.hongqivr.com/RoSSo/beijing/" target="_blank" rel="nofollow">VR全景体验</a>)|二校：海淀区海淀北二街8号中关村SOHO`,
                `广州市天河区天河北路233号中信广场7308室(<a href="https://case.hongqivr.com/RoSSo/guangzhou/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `深圳市福田区金田路东福华路北岗厦皇庭中心37楼(<a href="https://case.hongqivr.com/RoSSo/shenzhen/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `杭州市西湖区学院路77号黄龙万科中心H座7层(<a href="https://case.hongqivr.com/RoSSo/hangzhou/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `南京市秦淮区中山南路1号南京中心20楼(<a href="https://case.hongqivr.com/RoSSo/nanjing/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `成都市锦江区成都国际金融中心IFS一号楼3203-3206室(<a href="https://case.hongqivr.com/RoSSo/chengdu/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `武汉市武昌区中北路9号长城汇T3-2F(<a href="https://case.hongqivr.com/RoSSo/wuhan/" target="_blank" rel="nofollow">VR全景体验</a>)`,
                `陕西省西安市碑林区南门外南关正街88号长安国际中心C座507`,
                `东京都新宿区2丁目`
            ],
            li3: [
                "上海外国语大学立泰学院剑桥国际中心：上海市虹口区大连西路550号",
                "橘郡国际学校北京校区：北京市海淀区苏州街29号北京八一学校西门",
                "深圳市美中学校RoSSo国际部：广东省深圳市龙华区环观南路103号",
                "合肥市协和双语学校RoSSo艺术中心：合肥市滨湖新区嘉陵江路666号",
                "橘郡国际学校呼市校区：内蒙古呼和浩特新城区囫囵南路1号呼市二中国际部",
            ],
            formLabelAlign: {
                name: '',
                tel: '',
                region: '',
                region2: '',
                major: '',
            },
            labelPosition: 'right',
        }
    },
    created() {
        this.getPCbanner()
        // this.baidu()

    },
    methods: {
        onSubmit() {
            // console.log('submit!',this.formInline);
            var currentURL = window.location.href;
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }
            var patt = /^1[3-9][0-9]{9}$/

            if (this.formLabelAlign.name == "" || this.formLabelAlign.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');

            } else {
                // console.log("不是空");

                if (patt.test(this.formLabelAlign.tel)) {

                    // console.log("可以发请求");
                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/index/common/getform", {
                        "name": this.formLabelAlign.name,
                        "tel": this.formLabelAlign.tel,
                        "major": this.formLabelAlign.major,
                        "url": window.location.href,
                        "status": status,
                    }).then((data) => {
                        console.log(data, "response");
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }).catch(function (error) {
                        console.log(error, "error");
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }


        },

        navLineClass() {


            var nav_line = document.getElementsByClassName("nav_line")
            var w = window.innerWidth

            // console.log((w - 1261) / 2);
            nav_line[0].style.width = 630 + (w - 1261) / 2 + "px"

        },
        baidu() {
            // axios({
            //   method: 'post',
            //   url: 'https://api.baidu.com/json/sms/service/FcTransTraceApiService/getFcTransTraceList',
            //   data: {
            //     "transName": "example string",
            //     "traceTargetList": [1, 2, 3],
            //     "transModeList": [4, 6, 7, 8, 9, 10, 11, 23, 24],
            //     "transStatusList": [0, 1, 2, 3],
            //     "limit": [1]
            //   }
            // }).then((data) => {
            //   console.log(data, "baidu");
            // })
            axios.get('http://api.baidu.com/json/sms/service/FcTransTraceApiService/getFcTransTraceList', {
                params: {
                    "transName": "example string",
                    "traceTargetList": [1, 2, 3],
                    "transModeList": [4, 6, 7, 8, 9, 10, 11, 23, 24],
                    "transStatusList": [0, 1, 2, 3],
                    "limit": [1]

                }
            }).then((data) => {
                console.log(data);
            })
        },
        clickCourse() {
            var course_position = document.getElementsByClassName("course_position")
            course_position[0].style.display = "none"
        },
        leaveCourse() {
            var course_position = document.getElementsByClassName("course_position")
            course_position[0].style.display = "none"
        },
        mouseCourse() {
            // console.log("mmm");
            var course_position = document.getElementsByClassName("course_position")
            course_position[0].style.display = "flex"
        },

        clickCourse2() {
            var course_position2 = document.getElementsByClassName("course_position2")
            course_position2[0].style.display = "none"
        },
        leaveCourse2() {
            var course_position2 = document.getElementsByClassName("course_position2")
            course_position2[0].style.display = "none"
        },
        mouseCourse2() {
            // console.log("mmm22222");
            var course_position2 = document.getElementsByClassName("course_position2")
            course_position2[0].style.display = "flex"
        },
        hidBlack() {
            if (coverBlack.style.display == "block") {
                coverBlack.style.display = "none"
            } else {
                coverBlack.style.display = "block"
            }
        },
        hidBlack2() {
            if (coverBlack2.style.display == "block") {
                coverBlack2.style.display = "none"
            } else {
                coverBlack2.style.display = "block"
            }
        },
        eCoverBlack2(e) {
            e.stopPropagation()
        },
        hidCover() {
            cover.style.display = "none"
            document.body.style.overflow = "auto";
        },
        showCover() {
            cover.style.display = "block"
            document.body.style.overflow = "hidden";
        },
        getPCbanner() {
            axios.get("https://public.rossoarts.com/api/v1/getPcBanner").then((data) => {
                // console.log(data,"banner");
            })
        },
        navJop() {
            console.log("作品集 women");
            // window.open("https://public.rossoarts.com/gdzpj.html","_blank")
        },
        jopkefu() {
            window.open("https://www11c1.53kf.com/webCompany.php?kf_sign=DI0MjMTY4MkzODE1NTc0NTYwMDMzMDAzNzIxNjgzNTM=&arg=10168353&style=1&guest_id=12950638033002&language=zh-cn&charset=gbk&referer=http%3A%2F%2Fwww.rossoarts.com%2F&keyword=http%3A%2F%2Flocalhost%3A8081%2F&tpl=crystal_blue&uid=392070b4b477c77869f6a6a47a1dfee8&is_group=&timeStamp=1680242953439&ucust_id=", '_blank')
        },
        goHome() {
            console.log("go");
            // loGo.src = "./img/logo2.png"
            // this.$router.replace("/")
        },
        mouse2(val) {
            // console.log(val);
            if (val == 1) {

                show2.innerHTML = this.li2[val]
            } else {
                show2.innerHTML = this.li2[val]
            }
            var lis2 = document.getElementsByClassName("li2")

            for (var i = 0; i < lis2.length; i++) {
                lis2[i].style.fontWeight = "normal"
            }
            lis2[val].style.fontWeight = "bold"

        },
        mouse3(val) {
            // console.log(val);
            show3.innerHTML = this.li3[val]
            if (val == 2) {
                show4.style.display = "block"
            } else {
                show4.style.display = "none"
            }
            var lis3 = document.getElementsByClassName("li3")

            for (var i = 0; i < lis3.length; i++) {
                lis3[i].style.fontWeight = "normal"
            }
            lis3[val].style.fontWeight = "bold"

        },
        mouse1(val) {

            show1.innerHTML = this.li1[val]
            var lis = document.getElementsByClassName("li")

            for (var i = 0; i < lis.length; i++) {
                lis[i].style.fontWeight = "normal"
            }
            lis[val].style.fontWeight = "bold"
            // liFirst.style.color = "#999"
        },
        navScroll() {
            window.addEventListener("scroll", () => {
                var scrollTop = document.documentElement.scrollTop
                // console.log(scrollTop);

                if (scrollTop > 200) {
                    nav2_box.style.height = "90px";
                    nav2.style.height = "90px";
                    nav2.style.borderBottom = "2px solid #000";
                    nav1.style.display = "none";
                    app.style.marginTop = "100px";
                    // nav2.style.overflow = "hidden";
                } else if (scrollTop == 0) {
                    // console.log("0000000");
                    nav1.style.display = "flex";
                    nav2_box.style.height = "0px";
                    nav2.style.height = "0px";
                    nav2.style.border = "none";

                    app.style.marginTop = "0px";
                }
            })
        },
    },
    mounted() {
        this.navScroll()
        this.navLineClass()
        window.onresize = function () {

            // console.log(window.innerWidth);
            var nav_line = document.getElementsByClassName("nav_line")
            var w = window.innerWidth

            // console.log((w - 1261) / 2);
            nav_line[0].style.width = 630 + (w - 1261) / 2 + "px"
        }
    }
}

</script>

<style lang="less"></style>
